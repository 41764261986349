$color: var(--scrollIndicator) !default;
$backgroundColor: var(--scrollBackground) !default;

.soft-scroll {
  &.vertical,
  &-vertical {
    overflow-y: auto;
  }

  &.horizontal,
  &-horizontal {
    overflow-x: auto;
  }

  &.no-horizontal,
  &no-horizontal {
    overflow-x: hidden;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
    background-color: $backgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
    background-color: $backgroundColor;
  }
}
