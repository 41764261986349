[theme='dark'] {
  --colorSelected: #28303d;

  --background: #212328;
  --shellContentBackground: #212328;
  --shellMenuBarBackground: #1a1c21;
  --shellActionBarBackground: #1a1c21;

  --colorSurface: #2e333c;
  --colorSurfaceSecondary: #1c1e25;
  --colorSurfaceTertiary: #3c434d;

  --panelBackground: #1a1c21;

  --colorBorder: #4c535e;
  --colorBorderLight: #3e444d;
  --inputBorder: 1px solid #4c535e;

  --scrollIndicator: #47677d;
  --scrollBackground: var(--colorSurfaceTertiary);

  --skeletonColorBackground: var(--colorSurfaceTertiary);
  --colorTopContainerBackground: #1a1c21;

  --cardBoxShadow: 2px 2px 3px -1px rgba(10, 11, 12, 0.19),
                  -1px -1px 3px -1px rgba(10, 11, 12, 0.15);

  --colorDisabled: #404348;
  --colorReadonly: #404348;

  --colorText: #FFFFFF;
  --colorTextLight: #b5b8bb;
  --colorTextLighter: #6e747c;

  --chipColor: var(--colorSurfaceTertiary);
  --colorHover: #3c434d;

  --colorPrimary: #3477b1;
  --colorPrimary900: #8bc3f8;
  --colorPrimary800: #6eaeea;
  --colorPrimary700: #5d9dd9;
  --colorPrimary600: #4e8abd;
  --colorPrimary500: #3477b1;
  --colorPrimary400: #2470b3;
  --colorPrimary300: #1d598c;
  --colorPrimary200: #1a4066;
  --colorPrimary100: #183145;
  --colorPrimary50: #0f1c26;
  --colorPrimarySelection: #314054;

  --colorPrimaryContrast: #FFFFFF;
  --colorPrimaryContrast50: #FFFFFF;
  --colorPrimaryContrast100: #FFFFFF;
  --colorPrimaryContrast200: #FFFFFF;
  --colorPrimaryContrast300: #FFFFFF;
  --colorPrimaryContrast400: #FFFFFF;
  --colorPrimaryContrast500: #FFFFFF;
  --colorPrimaryContrast600: #FFFFFF;
  --colorPrimaryContrast700: #000000;
  --colorPrimaryContrast800: #000000;
  --colorPrimaryContrast900: #000000;

  --colorSecondary: #b7cae0;

  --colorSecondary900: #f8fafc;
  --colorSecondary800: #edf2f7;
  --colorSecondary700: #e2eaf3;
  --colorSecondary600: #cddae9;
  --colorSecondary500: #b7cae0;
  --colorSecondary400: #a5b6ca;
  --colorSecondary300: #748291;
  --colorSecondary200: #6e7986;
  --colorSecondary100: #5a636e;
  --colorSecondary50: #30343a;

  --colorSecondaryContrast: #000000;
  --colorSecondaryContrast50:#000000;
  --colorSecondaryContrast100: #000000;
  --colorSecondaryContrast200: #000000;
  --colorSecondaryContrast300: #000000;
  --colorSecondaryContrast400: #000000;
  --colorSecondaryContrast500: #FFFFFF;
  --colorSecondaryContrast600: #FFFFFF;
  --colorSecondaryContrast700: #FFFFFF;
  --colorSecondaryContrast800: #FFFFFF;
  --colorSecondaryContrast900: #FFFFFF;

  --colorAccent: #32998f;
  --colorAccent900: #86b3ab;
  --colorAccent800: #78b3a9;
  --colorAccent700: #5bb5a7;
  --colorAccent600: #4ca89e;
  --colorAccent500: #32998f;
  --colorAccent400: #268071;
  --colorAccent300: #178573;
  --colorAccent200: #1f665a;
  --colorAccent100: #164d44;
  --colorAccent50: #051815;
  --colorAccentSelection: #c5e8e3;

  --colorAccentContrast: #FFFFFF;
  --colorAccentContrast50: #FFFFFF;
  --colorAccentContrast100: #FFFFFF;
  --colorAccentContrast200: #FFFFFF;
  --colorAccentContrast300: #FFFFFF;
  --colorAccentContrast400: #FFFFFF;
  --colorAccentContrast500: #FFFFFF;
  --colorAccentContrast600: #FFFFFF;
  --colorAccentContrast700: #000000;
  --colorAccentContrast800: #000000;
  --colorAccentContrast900: #000000;

  //Danger
  --colorDanger: #c9384e;
  --colorDanger900: #ffa6ae;
  --colorDanger800: #ff7686;
  --colorDanger700: #f64d66;
  --colorDanger600: #d94158;
  --colorDanger500: #c9384e;
  --colorDanger400: #b9384c;
  --colorDanger300: #8f2f3e;
  --colorDanger200: #702732;
  --colorDanger100: #502329;
  --colorDanger50: #2f1519;
  --colorDangerContrast: #FFFFFF;
  --colorDangerContrast50: #FFFFFF;
  --colorDangerContrast100: #FFFFFF;
  --colorDangerContrast200: #FFFFFF;
  --colorDangerContrast300: #FFFFFF;
  --colorDangerContrast400: #FFFFFF;
  --colorDangerContrast500: #FFFFFF;
  --colorDangerContrast600: #FFFFFF;
  --colorDangerContrast700: #000000;
  --colorDangerContrast800: #000000;
  --colorDangerContrast900: #000000;

  //Danger
  --colorError: #e0586c;
  --colorError900: #ffa6ae;
  --colorError800: #ff7686;
  --colorError700: #f64d66;
  --colorError600: #d94158;
  --colorError500: #e0586c;
  --colorError400: #cc5062;
  --colorError300: #b34656;
  --colorError200: #8c3744;
  --colorError100: #662933;
  --colorError50: #4a1e25;
  --colorErrorContrast: #FFFFFF;
  --colorErrorContrast50: #FFFFFF;
  --colorErrorContrast100: #FFFFFF;
  --colorErrorContrast200: #FFFFFF;
  --colorErrorContrast300: #FFFFFF;
  --colorErrorContrast400: #FFFFFF;
  --colorErrorContrast500: #FFFFFF;
  --colorErrorContrast600: #FFFFFF;
  --colorErrorContrast700: #000000;
  --colorErrorContrast800: #000000;
  --colorErrorContrast900: #000000;

  // SUCCESS COLOR

  --colorSuccess900: #75bf9a;
  --colorSuccess800: #47c98c;
  --colorSuccess700: #37bf80;
  --colorSuccess600: #36b378;
  --colorSuccess500: #347558;
  --colorSuccess400: #2e664d;
  --colorSuccess300: #2d5943;
  --colorSuccess200: #2b4739;
  --colorSuccess100: #253d31;
  --colorSuccessContrast900: #000000;
  --colorSuccessContrast800: #000000;
  --colorSuccessContrast700: #000000;
  --colorSuccessContrast600: #FFFFFF;
  --colorSuccessContrast500: #FFFFFF;
  --colorSuccessContrast400: #FFFFFF;
  --colorSuccessContrast300: #FFFFFF;
  --colorSuccessContrast200: #FFFFFF;
  --colorSuccessContrast100: #FFFFFF;

  --colorSuccess: #347558;
  --colorSuccessBackground: #335d4b;

  // WARNING COLOR
  --colorWarning900: #cebfb1;
  --colorWarning800: #bba791;
  --colorWarning700: #facfa9;
  --colorWarning600: #ffd0a1;
  --colorWarning500: #d09d72;
  --colorWarning400: #d59968;
  --colorWarning300: #cc9466;
  --colorWarning200: #80563a;
  --colorWarning100: #623a21;
  --colorWarningContrast100: #000000;
  --colorWarningContrast200: #000000;
  --colorWarningContrast300: #000000;
  --colorWarningContrast400: #FFFFFF;
  --colorWarningContrast500: #FFFFFF;
  --colorWarningContrast600: #FFFFFF;
  --colorWarningContrast700: #FFFFFF;
  --colorWarningContrast800: #FFFFFF;
  --colorWarningContrast900: #FFFFFF;

  --colorWarning: #d09d72;
  --colorWarningBackground: #694b35;

  .ngx-charts .gridline-path {
    stroke: var(--colorBorder);
  }

  .soft-message-bar {
    &-warning,
    &.warning {
      .soft-message-bar-icon {
        color:  var(--colorText);
      }
    }

    &-success,
    &.success {
      .soft-message-bar-icon {
        color:  var(--colorText);
      }
    }

    &-error,
    &.error {
      .soft-message-bar-icon {
        color:  var(--colorText);
      }
    }
  }
}
