@use '../abstracts/variables';

.soft-entity-input-container soft-entity-picker .entity-picker-container {
  border-width: 1px 1px 1px 1px;
}

.entity-picker {
  position: fixed;
  background-color: var(--colorSurface);
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: var(--fontFamily);
  width: 100vw;
  max-height: 90vh;
  max-height: 90dvh;
  z-index: 501;

  @apply shadow-lg;

  .title {
    width: 100%;
    z-index: 501;
    background-color: var(--colorSurface);
  }

  @media only screen and (min-width: variables.$breakpointS) {
    max-width: 80vw;
    padding: 20px;

    position: fixed;
    //bottom: auto;
    top: 5vh;
    left: auto;
    right: auto;
  }
  @media only screen and (min-width: variables.$breakpointL) {
    max-width: 60vw;
  }

}
