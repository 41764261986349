@use '../abstracts/variables';

@mixin container-row-attributes($postfix) {
  &-start#{$postfix},
  &.start#{$postfix} {
    justify-content: flex-start;
  }
  &-end#{$postfix},
  &.end#{$postfix} {
    justify-content: flex-end;
  }
  &-no-wrap#{$postfix},
  &.no-wrap#{$postfix} {
    flex-wrap: nowrap;
  }
  &-wrap#{$postfix},
  &.wrap#{$postfix} {
    flex-wrap: wrap;
  }
  &-top#{$postfix},
  &.top#{$postfix} {
    align-items: flex-start;
  }
  &-bottom#{$postfix},
  &.bottom#{$postfix} {
    align-items: flex-end;
  }

  &-center-h#{$postfix},
  &.center-h#{$postfix} {
    justify-content: center;
  }
  &-center-v#{$postfix},
  &.center-v#{$postfix} {
    align-items: center;
  }
  &-center#{$postfix},
  &.center#{$postfix} {
    justify-content: center;
    align-items: center;
  }

  &-space-between#{$postfix},
  &.space-between#{$postfix} {
    justify-content: space-between;
  }
}

@mixin container-row($postfix) {
  &-row#{$postfix},
  &.row#{$postfix} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-row,
  &.row,
  &-row#{$postfix},
  &.row#{$postfix} {
    @include container-row-attributes($postfix);
  }
}

@mixin container-column-attributes($postfix) {
  &-start#{$postfix},
  &.start#{$postfix} {
    align-items: flex-start;
  }
  &-end#{$postfix},
  &.end#{$postfix} {
    align-items: flex-end;
  }
  &-top#{$postfix},
  &.top#{$postfix} {
    justify-content: flex-start;
  }
  &-bottom#{$postfix},
  &.bottom#{$postfix} {
    justify-content: flex-end;
  }

  &-center-h#{$postfix},
  &.center-h#{$postfix} {
    align-items: center;
  }
  &-center-v#{$postfix},
  &.center-v#{$postfix} {
    justify-content: center;
  }
  &-center#{$postfix},
  &.center#{$postfix} {
    justify-content: center;
    align-items: center;
  }

  &-space-between#{$postfix},
  &.space-between#{$postfix} {
    justify-content: space-between;
  }
}

@mixin container-column($postfix) {
  &-column#{$postfix},
  &.column#{$postfix} {
    flex-direction: column;
    justify-content: center;
  }
  &-column,
  &.column,
  &-column#{$postfix},
  &.column#{$postfix} {
    @include container-column-attributes($postfix);
  }
}

.soft-container,
.c {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &-row,
  &.row {
    flex-direction: row;
    flex-wrap: wrap;

    &-start,
    &.start,
    &.row-start {
      justify-content: flex-start;
    }
    &-end,
    &.end,
    &.row-end {
      justify-content: flex-end;
    }
    &-top,
    &.top,
    &.row-top {
      align-items: flex-start;
    }
    &-bottom,
    &.bottom,
    &.row-bottom {
      align-items: flex-end;
    }
    &-center-h,
    &.center-h &.row-center-h {
      justify-content: center;
    }
    &-center-v,
    &.center-v,
    &.row-center-v {
      align-items: center;
    }
    &-no-wrap,
    &.no-wrap {
      flex-wrap: nowrap;
    }
    &-wrap,
    &.wrap {
      flex-wrap: wrap;
    }
  }

  &-column,
  &.column {
    flex-direction: column;

    &-start,
    &.start,
    &.column-start {
      align-items: flex-start;
    }
    &-end,
    &.end,
    &.column-end {
      align-items: flex-end;
    }
    &-top,
    &.top,
    &.column-top {
      justify-content: flex-start;
    }
    &-bottom,
    &.bottom,
    &.column-bottom {
      justify-content: flex-end;
    }
    &-center-h,
    &.center-h &.column-center-h {
      align-items: center;
    }
    &-center-v,
    &.center-v,
    &.column-center-v {
      justify-content: center;
    }
  }

  &-center,
  &.center {
    justify-content: center;
    align-items: center;
    & > .soft-container {
      width: auto;
    }
  }

  &-space-between,
  &.space-between {
    justify-content: space-between;
  }
  &-auto-width,
  &.auto-width {
    width: auto;
  }

  @media only screen and (max-width: variables.$breakpointS) {
    @include container-row('-xs');
    @include container-column('-xs');
  }
  @media only screen and (min-width: variables.$breakpointS) {
    @include container-row('-s');
    @include container-column('-s');
  }
  @media only screen and (min-width: variables.$breakpointM) {
    @include container-row('-m');
    @include container-column('-m');
  }
  @media only screen and (min-width: variables.$breakpointL) {
    @include container-row('-l');
    @include container-column('-l');
  }
  @media only screen and (min-width: variables.$breakpointXL) {
    @include container-row('-xl');
    @include container-column('-xl');
  }
}
