@use 'themes/default';
@use 'themes/test';
@use 'themes/new';
@use 'themes/dark';

@use 'base/reset';
@use 'base/typography';
@use 'base/cursor';
@use 'base/scroll';
@use 'base/backdrop';
@use 'base/z-index';

@use 'animations/shaking';

@use 'components/badge';
@use 'components/buttons';
@use 'components/card';
@use 'components/chip';
@use 'components/combo-box';
@use 'components/color-input';
@use 'components/date-picker';
@use 'components/draw-input';
@use 'components/entitiy-picker';
@use 'components/entity-input';
@use 'components/input';
@use 'components/label';
@use 'components/link';
@use 'components/message-bar';
@use 'components/checkbox';
@use 'components/radio';
@use 'components/tab';
@use 'components/validation';
@use 'components/master-detail';
@use 'components/modal';
@use 'components/file-input';
@use 'components/select';
@use 'components/separator';
@use 'components/signature-input';
@use 'components/skeleton';
@use 'components/tool-tip';
@use 'components/toggle-switch';
@use 'components/loading-bar';
@use 'components/loading-spinner';
@use 'components/loading-skeleton';

@use 'directives/scan';
@use 'directives/valueConverter';


@use 'layout/application';
@use 'layout/container';
@use 'layout/grid';
@use 'layout/form';
@use 'layout/placement';
@use 'layout/table';
@use 'layout/list';
@use 'layout/utils';
@use 'layout/required';
@use 'layout/section';
@use 'layout/sizing';
@use 'layout/position';
@use 'layout/top-container';

@import '~@angular/cdk/overlay-prebuilt.css';

// $fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts" !default;
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/v4-shims';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';

// $fa-font-path: "node_modules/@fortawesome/fontawesome-pro/webfonts" !default;
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-pro/scss/v4-shims";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "node_modules/@fortawesome/fontawesome-pro/scss/light";
@import "node_modules/@fortawesome/fontawesome-pro/scss/thin";
@import "node_modules/@fortawesome/fontawesome-pro/scss/duotone";

//mobiscroll
@import 'node_modules/@mobiscroll/angular/dist/css/mobiscroll';

.custom-width > *:not(router-outlet) {
  width: 100%;
}
