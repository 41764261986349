@use 'sass:color';

@mixin grid-template-x($name, $attr, $attr-count: 10, $value: auto) {
  $attr-list: null;
  @for $i from 1 through $attr-count {
    .#{$name}-#{$i} {
      #{$attr}: repeat(#{$i}, $value);
    }

    $attr-list: append($attr-list, unquote('.#{$attr}-#{$i}'), comma);
  }

  #{$attr-list} {
    //append style to all classes
  }
}

@mixin grid-x($name, $attr, $attr-count: 10) {
  $attr-list: null;
  @for $i from 1 through $attr-count {
    .#{$name}-#{$i} {
      #{$attr}: #{$i};
    }
    $attr-list: append($attr-list, unquote('.#{$attr}-#{$i}'), comma);

    @if $i < $attr-count {
      @for $j from ($i + 1) through $attr-count {
        .#{$name}-#{$i}-#{$j} {
          #{$attr}: #{$i} / #{$j + 1};
        }

        $attr-list: append($attr-list, unquote('.#{$attr}-#{$i}-#{$j}'), comma);
      }
    }
  }

  #{$attr-list} {
    //append style to all classes
  }
}

@mixin userSelect($mode: none) {
  -moz-user-select: $mode;
  -ms-user-select: $mode;
  -webkit-user-select: $mode;
  user-select: $mode;
}

@mixin getLightness($color) {
}

@mixin placement($attribute, $name, $base-value, $attr-count) {
  @for $i from 1 through $attr-count {
    $value: $base-value * $i;
    .#{$name}-#{$value} {
      #{$attribute}: unquote($value + 'px');
    }
  }
}
