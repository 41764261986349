@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

@mixin z-index() {
  $attr-list: null;
  @for $i from 1 through 4 {
    @for $j from 1 through 10 {
      .z-#{$j * pow(10, $i)} {
        z-index: #{$j * pow(10, $i)};
      }
    }
  }
}

@include z-index();
