soft-title-bar {
  z-index: 250;
  color: var(--shellTitleBarText);
  background: var(--shellTitleBarBackground);

  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  justify-content: flex-start;
  max-width: 100vw;

  .title-bar-command:hover {
    background-color: var(--shellTitleBarBackgroundHover);
  }
}
