@layer components {
  .skeleton {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: var(--skeletonColorBackground);

    &::after {
      @apply absolute left-0 right-0 top-0 bottom-0;
      transform: translateX(-100%);
      animation: shimmer 2s infinite;
      content: '';
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.45) 60%,
        rgba(#fff, 0)
      );
    }

    // move ::after pseudo-element to create shimmer effect
    // overflow will be clipped so it looks like a nice animation
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
