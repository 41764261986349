soft-chip {
  min-width: 32px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 0.25rem;
  width: auto;
  height: auto;
  padding: 0.25rem 0.75rem;
  background-color: var(--chipColor);
  color: var(--chipColorText);
  border-radius: var(--chipBorderRadius);
  @apply text-sm font-medium;
}

.soft-chip {
  border-radius: var(--chipBorderRadius);
  padding: 0.225rem 0.75rem 0.125rem 0.75rem;
  background-color: var(--chipColor);
}

.soft-chip-icon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: var(--chipColorText);
  border-radius: 1rem;

  &:hover {
    color: var(--colorDanger);
  }
}
