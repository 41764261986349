.soft-loading-spinner {
  color: var(--spinnerColor);
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  &.large {
    width: 80px;
    height: 80px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &-part {
    animation: soft-loading-spinner-animation 1.2s linear infinite;
    transform-origin: 20px 20px;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 3px;
      left: 17px;
      width: 3px;
      height: 9px;
      border-radius: 20%;
      background: var(--spinnerBackground);
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}

.large > .soft-loading-spinner {
  width: 80px;
  height: 80px;
  & > .soft-loading-spinner-part {
    transform-origin: 40px 40px;
  }
  & > .soft-loading-spinner-part:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px !important;
    width: 6px !important;
    height: 18px !important;
    border-radius: 20%;
    background: var(--spinnerBackground);
  }
}

.small > .soft-loading-spinner {
  width: 20px;
  height: 20px;
  & > .soft-loading-spinner-part {
    transform-origin: 12px 12px;
  }

  & > .soft-loading-spinner-part:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 9.5px;
    width: 2px;
    height: 5px;
    border-radius: 20%;
    background: var(--spinnerBackground);
  }
}

@keyframes soft-loading-spinner-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.soft-spinner {
  min-height: 56px;
  background-image: url('/assets/modules/ui-core/images/spinner.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 56px;
  content: '';
  display: inline-block;
  user-select: none;

  &.small {
    min-height: 24px;
    background-image: url('/assets/modules/ui-core/images/spinner-small.svg');
    width: 24px;
  }

  &.large {
    min-height: 78px;
    background-image: url('/assets/modules/ui-core/images/spinner-large.svg');
    width: 78px;
  }
}
