.soft-link {
  color: var(--colorLink);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    transition: all 0.3s ease;
  }

  &.small,
  &-small {
    font-size: 0.65rem;
    font-weight: 600;
  }
}
