soft-shell {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh; //support für ältere Browser, bei denen dvh nicht unterstützt wird (Sonst Problem mit älteren Zebra MDEs).
  height: 100dvh;
  color: var(--colorText);
}

.reset-shell-padding {
  @apply flex flex-col border-b;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  background-color: var(--colorTopContainerBackground);
}
