@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'libs/framework/ui-core/styles/main';
@import "../../../../libs/softapps/core/styles/main";
@import 'libs/framework/dynamic/styles/main';
@import 'libs/framework/capacitor/styles/main';

// $fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts" !default;
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/v4-shims';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';
