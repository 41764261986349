html,
body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  box-sizing: border-box;
  font: var(--font);

  max-height: 100dvh;
  overflow: hidden;
}

body {
  touch-action: pan-x pan-y;
  margin-top: env(safe-area-inset-top);
  margin-top: constant(safe-area-inset-top);
}

input,
textarea {
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

soft-shell {
  width: 100%
}
