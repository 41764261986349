.soft-section {
  & > header {
    overflow: hidden;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--sectionHeaderColor);
    letter-spacing: 0.6px;
    margin-bottom: 0.75rem;

    &:after {
      content: '';
      display: inline-block;
      height: 0.5em;
      vertical-align: bottom;
      width: 100%;
      margin-right: -100%;
      margin-left: 10px;
      border-top: 1px solid var(--sectionHeaderBorderColor);
    }
  }
}
