@use './../abstracts/mixins';

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.soft-list {
  list-style: none;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-item {
    @include mixins.userSelect(none);
    padding: 0.5rem 0 0.5rem 0;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .separate,
    &.separate {
      border-bottom: var(--inputBorder);
      .soft-list-item:last-child {
        border-bottom: none;
      }
    }

    &-title {
      margin: 0;
      font-size: 1.25rem;
      padding: 0;
      font-weight: bold;
    }

    &-action {
      margin: 0.5rem 0;
    }
    &-currency {
      float: right;
    }
    &-number {
      float: right;
    }

    &.selected {
      background-color: var(--colorSelected);
    }
  }
}
