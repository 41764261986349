.soft-loading-bar {
  width: 100%;
  height: 4px;
  background: var(--transparent);
  position: relative;
  overflow-x: hidden;

  &-indicator {
    background: var(--loadingBarColor);
    width: 60%;
    position: absolute;
    height: 100%;
    left: -40%;
    transition: all 1s linear;
    animation-name: load;
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}

.bounce > .soft-loading-bar > .soft-loading-bar-indicator {
  animation-name: bounce;
}
.ease > .soft-loading-bar > .soft-loading-bar-indicator {
  animation-timing-function: ease;
}
.ease-in > .soft-loading-bar > .soft-loading-bar-indicator {
  animation-timing-function: ease-in;
}
.ease-out > .soft-loading-bar > .soft-loading-bar-indicator {
  animation-timing-function: ease-out;
}
.ease-in-out > .soft-loading-bar > .soft-loading-bar-indicator {
  animation-timing-function: ease-in-out;
}

@keyframes load {
  0% {
    left: -60%;
  }

  100% {
    left: 160%;
  }
}

@keyframes bounce {
  0% {
    left: 0;
  }

  50% {
    left: 40%;
  }

  100% {
    left: 0;
  }
}
