.soft-badge {
  height: 1rem;
  min-width: 1rem;
  width: auto;
  display: grid;
  place-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.75rem;
  color: var(--panelBackground);
  background-color: var(--colorError);
  border-radius: 1rem;
}
