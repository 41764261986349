soft-side-actions {
  background: var(--shellActionBarBackground);
  color: var(--shellActionBarText);

  .action-menu-toggle {
    color: var(--shellActionBarHeaderText);
    background: var(--shellActionBarHeaderBackground);
    height: 3rem;
    min-height: 3rem;
    cursor: pointer;
    &:hover {
      background-color: var(--shellActionBarHeaderBackgroundHover);
      transition: all 0.15s ease-in-out;
    }
  }

  .action-menu-header {
    @apply flex w-full justify-between items-center h-12 min-h-[3rem] pl-4;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--shellActionBarHeaderText);
    background: var(--shellActionBarHeaderBackground);

    .action-menu-close {
      @apply h-full pl-4 pr-4 flex items-center justify-center cursor-pointer;
      color: var(--shellActionBarHeaderText);

      &:hover {
        background-color: var(--shellActionBarHeaderBackgroundHover);
        transition: all 0.15s ease-in-out;
      }
    }
  }
}
