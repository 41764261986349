soft-color-input {
  @apply flex;
  width: 100%;
  height: 2rem;

  &.center {
    @apply justify-center;
  }

  & > .color-input-container {
    width: 100%;
    height: 2rem;
  }

  &.circle {
    & > .color-input-container {
      width: 2rem;
      border-radius: 2rem;
      overflow: hidden;

      & > .color-input {
        border: 0;
        padding: 0;
        width: 200%;
        height: 200%;
        cursor: pointer;
        transform: translate(-25%, -25%);
      }
    }
  }

  &.rounded {
    & > .color-input-container {
      border-radius: 2rem;
      overflow: hidden;
      width: 100%;

      & > .color-input {
        border: 0;
        padding: 0;
        width: 200%;
        height: 200%;
        cursor: pointer;
        transform: translate(-25%, -25%);
      }
    }
  }
}

.color-input {
  height: 2rem;
}
