.side-menu, .action-menu {
  soft-menu-outlet {
    @apply flex-col;
  }
  soft-menu-item {
    @apply w-full;

    &.active {
      box-sizing: border-box;
      @apply relative;
      color: var(--shellMenuItemTextActive) !important;
      background: var(--shellMenuItemBackgroundActive) !important;
    }
    &.active::before {
      @apply absolute left-0 inline-block h-full w-[5px];
      content: '';
      background-color: var(--shellMenuItemActiveIndicator);
    }

    .menu-item-button {
      @apply w-full flex flex-row py-2.5 px-5 hover:bg-hover;
    }
    .menu-item-split-button {
      @apply flex flex-row flex-nowrap;
      div {
        @apply w-full;
      }
      button:nth-child(1) {
        @apply w-full hover:bg-hover py-2.5 px-5;
      }
      button:nth-child(2) {
        @apply hover:bg-hover py-2.5 px-5;
      }
    }
    .menu-item-text {
      @apply visible ml-2;
    }

    soft-icon {
      @apply w-5;
    }

    .menu-item-has-icon {
      @apply ml-2;
    }
  }

  .minified {
    button {
      @apply justify-center;
    }
    button:nth-child(2) {
      @apply w-auto text-sm;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .menu-item-text {
      @apply hidden;
    }
  }

}

.top-responsive-menu {
  @apply flex flex-row flex-nowrap justify-between items-center overflow-x-auto;

  soft-menu-item {
    @apply w-auto min-w-16;

    .menu-item-text {
      @apply ml-2 visible;
    }
  }

}


.bottom-responsive-menu {
  @apply flex flex-row flex-nowrap justify-between items-center overflow-x-auto;

  soft-menu-item {
    @apply w-auto min-w-16;

    .menu-item-button {
      @apply w-full flex flex-row justify-center items-center rounded h-16 ;
    }

    .menu-item-split-button {
      @apply flex flex-row flex-nowrap rounded h-16 w-full;

      div {
        @apply w-full;
      }
      button:nth-child(1) {
        @apply w-full flex flex-row justify-center items-center flex-nowrap;

        div {
          @apply justify-center;
        }
      }
      button:nth-child(2) {
      }
    }
    .menu-item-text {
      @apply ml-2 visible;
    }
  }

  soft-menu-item:last-child {
    @apply w-full mx-2;
  }
}

.top-tab-menu {
  @apply flex flex-row w-full flex-nowrap items-center overflow-x-auto;

  soft-menu-item {
    @apply px-4;
    transition: background-color 200ms ease-in;

    .menu-item-button {
      @apply w-full flex flex-row justify-center items-center h-11;
    }

    .menu-item-text {
      @apply visible;
    }

    .menu-item-has-icon {
      @apply ml-2;
    }

    &.active {
      box-sizing: border-box;
      @apply relative border-b-2;
      color: var(--colorPrimary) !important;
      background: var(--colorPrimarySelection) !important;
      border-bottom-color: var(--colorPrimary) !important;
    }
  }
}

.bottom-tab-menu {
  @apply flex flex-row w-full flex-nowrap items-center overflow-x-auto;
  box-shadow: 0px -2px 10px -1px rgba(0, 0, 0, 0.15);

  soft-menu-item {
    @apply w-full px-4;
    transition: background-color 200ms ease-in;

    .menu-item-button {
      @apply w-full flex flex-col justify-center items-center h-16;
    }

    .menu-item-text {
      @apply visible text-sm;
    }

    &.active {
      box-sizing: border-box;
      @apply relative border-t-4;
      color: var(--colorPrimary) !important;
      background: var(--colorPrimarySelection) !important;
      border-top-color: var(--colorPrimary) !important;
    }
  }
}
