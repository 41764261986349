.tight {
  margin: 0;
  padding: 0;
}

.trim-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(90%);
}

.pointer {
  cursor: pointer;
}

.v-stack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.collapse,
.collapse * {
  display: none;
  visibility: collapse !important;
  position: fixed;
  pointer-events: none;
}

.fluid {
  .no-wrap,
  &.no-wrap {
    white-space: nowrap;
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
