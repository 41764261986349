@use '../abstracts/variables';

asoft-master-detail {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .hide-on-mobile {
    display: none;
  }

  .soft-master-view {
    width: 100%;

    height: 100%;
    overflow-y: auto;

    &-selected {
      background-color: var(--colorSelected);
    }

    &-item {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      transition: all 200ms ease;

      &:hover {
        background-color: var(--colorHover);
        transition: all 200ms ease;
      }

      &:active {
        background-color: var(--colorSecondary200);
      }
    }
  }

  .soft-detail-view {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  @media screen and (min-width: variables.$breakpointS) {
    .hide-on-mobile {
      display: flex;
      flex-direction: column;
    }
    .soft-master-view {
      width: 33.33%;
      border-right: var(--inputBorder);

      &-item {
      }
    }
    .soft-detail-view {
      width: 66.67%;
      padding: 0 0 0 1rem;
    }
  }
}
