.required::after {
  color: var(--colorDanger700);
  content: '*';
}

.depended-required::after {
  color: var(--colorWarning700);
  content: '*';
}

@for $i from 1 through 10 {
  .required-group-#{$i}::after {
    color: var(--colorWarning700);
    font-size: 0.875em;
    vertical-align: super;
    content: '#{$i}';
  }
}
