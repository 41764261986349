[theme='new'] {
  --colorPrimary: #0177BF;
  --colorPrimary50: #CCE4F2;
  --colorPrimary100: #A6CFE9;
  --colorPrimary200: #80BBDF;
  --colorPrimary300: #5AA7D5;
  --colorPrimary400: #3492CC;
  --colorPrimary500: #0177BF;
  --colorPrimary600: #0165A2;
  --colorPrimary700: #015386;
  --colorPrimary800: #014169;
  --colorPrimary900: #00304C;
  --colorPrimarySelection: #E8EFF6;

  --colorPrimaryContrast: #FFFFFF;
  --colorPrimaryContrast50: #000000;
  --colorPrimaryContrast100: #000000;
  --colorPrimaryContrast200: #000000;
  --colorPrimaryContrast300: #000000;
  --colorPrimaryContrast400: #FFFFFF;
  --colorPrimaryContrast500: #FFFFFF;
  --colorPrimaryContrast600: #FFFFFF;
  --colorPrimaryContrast700: #FFFFFF;
  --colorPrimaryContrast800: #FFFFFF;
  --colorPrimaryContrast900: #FFFFFF;


  --colorAccent: #32DBBE;
  --colorAccent50: #CEF6F0;
  --colorAccent100: #BDF3EA;
  --colorAccent200: #9AEDDF;
  --colorAccent300: #77E7D4;
  --colorAccent400: #55E1C9;
  --colorAccent500: #32DBBE;
  --colorAccent600: #20B59B;
  --colorAccent700: #178573;
  --colorAccent800: #0F564A;
  --colorAccent900: #072621;
  --colorAccentSelection: #c5e8e3;

  --colorAccentContrast: #000000;
  --colorAccentContrast50: #000000;
  --colorAccentContrast100: #000000;
  --colorAccentContrast200: #000000;
  --colorAccentContrast300: #000000;
  --colorAccentContrast400: #000000;
  --colorAccentContrast500: #000000;
  --colorAccentContrast600: #000000;
  --colorAccentContrast700: #FFFFFF;
  --colorAccentContrast800: #FFFFFF;
  --colorAccentContrast900: #FFFFFF;

  --colorSelected: #CAEBFF;

}
