@use 'sass:meta';

/******************
****** Shell ******
******************/
$shellContentBackground: var(--background) !default;

$shellTitleBarBackground: var(--colorPrimary) !default;
$shellTitleBarBackgroundHover: var(--colorPrimary700) !default;
$shellTitleBarText: var(--colorPrimaryContrast) !default;

$shellActionBarBackground: #e9eeee !default;
$shellActionBarText: var(--colorText) !default;
$shellActionBarHeaderBackground: var(--colorPrimary) !default;
$shellActionBarHeaderBackgroundHover: var(--colorPrimary700) !default;
$shellActionBarHeaderText: var(--colorPrimaryContrast) !default;

$shellMenuBarBackground: #e9eeee !default;
$shellMenuBarText: var(--colorText) !default;
$shellMenuBarHeaderBackground: var(--colorPrimary) !default;
$shellMenuBarHeaderBackgroundHover: var(--colorPrimary700) !default;
$shellMenuBarHeaderText: var(--colorPrimaryContrast) !default;

$shellMenuItemText: var(--colorText) !default;
$shellMenuItemBackground: var(--transparent) !default;
$shellMenuItemBackgroundHover: var(--colorHover) !default;
$shellMenuItemActiveIndicator: var(--colorPrimary) !default;
$shellMenuItemTextActive: $shellMenuItemText !default;
$shellMenuItemBackgroundActive: var(--colorSelected) !default;
$shellMenuItemTextDisabled: var(--colorDisabled) !default;
$shellMenuItemBackgroundDisabled: $shellMenuItemBackground !default;

$shellSubMenuItemText: var(--colorText) !default;
$shellSubMenuItemBackground: rgba(255, 255, 255, 0.4) !default;
$shellSubMenuItemBackgroundHover: var(--colorHover) !default;
$shellSubMenuItemActiveIndicator: var(--colorPrimary) !default;
$shellSubMenuItemTextActive: $shellSubMenuItemText !default;
$shellSubMenuItemBackgroundActive: var(--colorSelected) !default;
$shellSubMenuItemTextDisabled: var(--colorDisabled) !default;
$shellSubMenuItemBackgroundDisabled: $shellSubMenuItemBackground !default;

$shellMenuTitleText: var(--colorTextLighter) !default;
$shellMenuSeparator: var(--separator) !default;

$shellMessageBarBackground: var(--panelBackground) !default;

$publicShellBranding: url('/assets/images/softline-logo.svg') !default;
$publicShellBrandingBackground: var(--colorPrimary) !default;
$publicShellBrandingSize: inherit !default;
$publicShellBrandingHeight: 50px !default;

$titleBarBrandingWidth: 50px;
$titleBarBranding: none;
$titleBarBrandingSize: contain;

/*
 * -----------------
 * Light (default) Theme
 * -----------------
 */
:root {
  --shellContentBackground: #{$shellContentBackground};

  --shellTitleBarBackground: #{$shellTitleBarBackground};
  --shellTitleBarBackgroundHover: #{$shellTitleBarBackgroundHover};
  --shellTitleBarText: #{$shellTitleBarText};

  --shellActionBarBackground: #{$shellActionBarBackground};
  --shellActionBarText: #{$shellActionBarText};
  --shellActionBarHeaderBackground: #{$shellActionBarHeaderBackground};
  --shellActionBarHeaderBackgroundHover: #{$shellActionBarHeaderBackgroundHover};
  --shellActionBarHeaderText: #{$shellActionBarHeaderText};

  --shellMenuBarBackground: #{$shellMenuBarBackground};
  --shellMenuBarText: #{$shellMenuBarText};
  --shellMenuBarHeaderBackground: #{$shellMenuBarHeaderBackground};
  --shellMenuBarHeaderBackgroundHover: #{$shellMenuBarHeaderBackgroundHover};
  --shellMenuBarHeaderText: #{$shellMenuBarHeaderText};

  --shellMenuItemText: #{$shellMenuItemText};
  --shellMenuItemBackground: #{$shellMenuItemBackground};
  --shellMenuItemBackgroundHover: #{$shellMenuItemBackgroundHover};
  --shellMenuItemActiveIndicator: #{$shellMenuItemActiveIndicator};
  --shellMenuItemTextActive: #{$shellMenuItemTextActive};
  --shellMenuItemBackgroundActive: #{$shellMenuItemBackgroundActive};
  --shellMenuItemTextDisabled: #{$shellMenuItemTextDisabled};
  --shellMenuItemBackgroundDisabled: #{$shellMenuItemBackgroundDisabled};

  --shellSubMenuItemText: #{$shellSubMenuItemText};
  --shellSubMenuItemBackground: #{$shellSubMenuItemBackground};
  --shellSubMenuItemBackgroundHover: #{$shellSubMenuItemBackgroundHover};
  --shellSubMenuItemActiveIndicator: #{$shellSubMenuItemActiveIndicator};
  --shellSubMenuItemTextActive: #{$shellSubMenuItemTextActive};
  --shellSubMenuItemBackgroundActive: #{$shellSubMenuItemBackgroundActive};
  --shellSubMenuItemTextDisabled: #{$shellSubMenuItemTextDisabled};
  --shellSubMenuItemBackgroundDisabled: #{$shellSubMenuItemBackgroundDisabled};

  --shellMenuTitleText: #{$shellMenuTitleText};
  --shellMenuSeparator: #{$shellMenuSeparator};

  --shellMessageBarBackground: #{$shellMessageBarBackground};

  --publicShellBranding: #{$publicShellBranding};
  --publicShellBrandingHeight: #{$publicShellBrandingHeight};
  --publicShellBrandingBackground: #{$publicShellBrandingBackground};
  --publicShellBrandingSize: #{$publicShellBrandingSize};

  --titleBarBrandingDisplay: none;
  --titleBarBrandingWidth: #{$titleBarBrandingWidth};
  --titleBarBranding: #{$titleBarBranding};
  --titleBarBrandingSize: #{$titleBarBrandingSize};

  --sideMenuBrandingDisplay: block;
  --sideMenuBrandingHeight: 100px;
  --sideMenuBranding: url('/assets/images/softline-logo.svg');
  --sideMenuBrandingSize: contain;
  --sideMenuBrandingOrigin: content-box;
}
