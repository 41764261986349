.soft-skeleton-loading {
  position: relative;
  background-color: var(--skeletonColorBackground);
  width: 100%;
  height: 1em;
  overflow: hidden;
  border-radius: 2px;
  margin-bottom: 0.125rem;
  animation: none;

  &:after,
  &:before {
    box-sizing: border-box;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      var(--skeletonColor),
      transparent
    );
    animation-name: loading;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 1.75s;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.pulse > .soft-skeleton-loading {
  animation-name: skeletonPulse;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-duration: 1.75s;

  &:after {
    animation: none;
  }
}

.fast > .soft-skeleton-loading {
  animation-duration: 1s;
  &:after {
    animation-duration: 1s;
  }
}

.slow > .soft-skeleton-loading {
  animation-duration: 3s;
  &:after {
    animation-duration: 3s;
  }
}

.linear > .soft-skeleton-loading {
  animation-timing-function: linear;
  &:after {
    animation-timing-function: linear;
  }
}

.complex-timing {
  animation-timing-function: cubic-bezier(0.73, 0.02, 0.48, 1.15);
  &:after {
    animation-timing-function: cubic-bezier(0.73, 0.02, 0.48, 1.15);
  }
}

@keyframes skeletonHighlight {
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

@keyframes skeletonPulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
