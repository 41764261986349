[theme='test'] {
  --colorPrimary: #f44336;
  --colorPrimary50: #ffebee;
  --colorPrimary100: #ffcdd2;
  --colorPrimary200: #ef9a9a;
  --colorPrimary300: #e57373;
  --colorPrimary400: #ef5350;
  --colorPrimary500: #f44336;
  --colorPrimary600: #e53935;
  --colorPrimary700: #d32f2f;
  --colorPrimary800: #c62828;
  --colorPrimary900: #b71c1c;
  --colorPrimarySelection: #efdada;

  --colorSelected: var(--colorPrimary100);
}
