$borderColor: #b7cae0 !default;

.soft-dynamic-form-field {
  width: 100%;
}

.entity-input-picker-content {
  height: 70vh;
}

.entity-input-picker-content,
.list,
.form {
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: $borderColor;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
  }
}

soft-dynamic-value {
  width: auto !important;
}
