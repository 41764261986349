soft-value-converter {
  @apply block w-full;

  &.error, .error>& {
    soft-input,
    soft-number-input,
    soft-date-input,
    soft-duration-input,
    soft-time-input,
    soft-duration-input,
    soft-entity-input,
    soft-entity-list-input,
    soft-entity-query-input,
    soft-select,
    soft-combo-box,
    soft-multiline-input,
    soft-password-input,
    soft-multi-select {
      border-width: 1px;
      border-color: var(--colorError500);
    }
  }
  &.warning, .warning>& {
    soft-input,
    soft-number-input,
    soft-date-input,
    soft-duration-input,
    soft-time-input,
    soft-duration-input,
    soft-entity-input,
    soft-entity-list-input,
    soft-entity-query-input,
    soft-select,
    soft-combo-box,
    soft-multiline-input,
    soft-password-input,
    soft-multi-select {
      border-width: 1px;
      border-color: var(--colorWarning500);
    }
  }
  &.success, .success>& {
    soft-input,
    soft-number-input,
    soft-date-input,
    soft-duration-input,
    soft-time-input,
    soft-duration-input,
    soft-entity-input,
    soft-entity-list-input,
    soft-entity-query-input,
    soft-select,
    soft-combo-box,
    soft-multiline-input,
    soft-password-input,
    soft-multi-select {
      border-width: 1px;
      border-color: var(--colorSuccess500);
    }
  }
  &.info, .info>& {soft-input,
  soft-number-input,
  soft-date-input,
  soft-duration-input,
  soft-time-input,
  soft-duration-input,
  soft-entity-input,
  soft-entity-list-input,
  soft-entity-query-input,
  soft-select,
  soft-combo-box,
  soft-multiline-input,
  soft-password-input,
  soft-multi-select {
    border-width: 1px;
    outline-color: var(--colorInfo500);
  }
  }
}
