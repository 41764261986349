.p-rel {
  position: relative;
}
.p-abs {
  position: absolute;
  &.right {
    right: 0;
  }
}
.p-fix {
  position: fixed;
}
