@use '../abstracts/variables' as variables;

$companyBranding: variables.$startShellCompanyBranding !default;
$companyBrandingHeight: variables.$startShellCompanyBrandingHeight !default;
// $backgroundColor: variables.$startShellBackgroundColor !default;
//$containerBackgroundColor: variables.$startShellContainerBackgroundColor !default;

soft-public-shell {
  background-color: var(--background);
  overflow: auto;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  transition: all 100ms ease;
  margin-top: auto;
}
.login-container {
  background-color: var(--colorSurface);
  padding: 2rem 2.5rem 1.5rem 2.5rem;
  border-radius: 4px;
  box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.09);
  width: 100vw;
  box-sizing: border-box;

  @media screen and (min-width: variables.$breakpointS) and (max-width: variables.$breakpointM) {
    width: 80vw;
  }

  @media screen and (min-width: variables.$breakpointM) and (max-width: variables.$breakpointL) {
    width: 60vw;
    min-width: 600px;
  }

  @media screen and (min-width: variables.$breakpointL) and (max-width: variables.$breakpointXL) {
    width: 50vw;
    min-width: 600px;
  }

  @media screen and (min-width: variables.$breakpointXL) {
    width: 40vw;
    min-width: 600px;
    max-width: 800px;
  }
}

.login-softapps-logo {
  height: 25px;
  width: 25px;
  background: #ffffff;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.login-company-branding {
  width: 100%;
  @if ($companyBranding) {
    min-height: $companyBrandingHeight;
    background: $companyBranding;
  }
}

.soft-app-info {
  font-size: 0.675rem;
  margin-top: auto;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  justify-items: flex-end;
  justify-content: center;
  padding: 10px 0;
}
