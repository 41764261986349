soft-tab-group {
  display: block;
}

.soft-tab-group {
  width: 100%;
  height: 44px;
  background-color: var(--colorSurface);
  display: flex;
  flex-direction: row;
  border-bottom: var(--separator);
}

.soft-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 60px;
  padding: 0 1rem;
  cursor: pointer;
  position: relative;
  transition: background-color 200ms ease-in;

  &.selected-tab {
    background-color: var(--colorPrimarySelection);
  }

  &-title {
    font-family: var(--fontFamily);
    color: var(--colorText);
    font-weight: normal;
    font-size: 1rem;
    position: relative;
  }

  &-indicator {
    position: absolute;
    height: 2px;
    background-color: var(--colorPrimary);
    bottom: -1px;
    width: 100%;
  }
}

.soft-tab-content {
  width: 100%;
  overflow-y: auto;
}
