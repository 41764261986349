@use '../abstracts/variables';

.shell-message-bar-wrapper {
  position: fixed;
  z-index: 999999;
  background-color: transparent;
  border-radius: 10px;
  bottom: 6.75rem;
  left: 1rem;
  right: 1rem;
  box-sizing: border-box;

  @media screen and (min-width: variables.$breakpointS) {
    margin: 0 80px;
    left: 0.25rem;
    right: 0.25rem;
    // top: 4rem;
   //  bottom: auto;
  }

  &.side-menu-opened {
    margin: 0 80px 0 260px;
  }

  &.action-menu-opened {
    margin: 0 260px 0 80px;
  }

  &.both-menues-opened {
    margin: 0 260px 0 260px;
    width: calc(100vw - 580px);
  }
}
