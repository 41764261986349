body {
  font-family: var(--fontFamiliy);
  color: var(--colorText);
}

text {
  fill: var(--colorText);
}

.uppercase {
  text-transform: uppercase;
}

.soft-text {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
  font-size: var(--fontSize);
  color: var(--colorText);

  &-xl,
  &.xl {
    font-size: var(--fontSizeXL);
  }
  &-l,
  &.l {
    font-size: var(--fontSizeL);
  }
  &-m,
  &.m {
    font-size: var(--fontSize);
  }
  &-s,
  &.s {
    font-size: var(--fontSizeS);
  }
  &-xs,
  &.xs {
    font-size: var(--fontSizeXS);
  }
  &-align-right,
  &.align-right {
    float: right;
  }
}

.soft-title {
  color: var(--colorTitle);
  letter-spacing: 0.25px;
  @apply text-lg font-semibold;
}

.soft-subtitle {
  font-weight: 400;
  color: var(--colorSubtitle);
  @apply text-base;
}

.soft-overline {
  color: var(--colorOverline);
  text-transform: uppercase;
  letter-spacing: 0.25px;
  margin-bottom: 0.25rem;
  font-weight: 700;
  @apply text-xs;
}

.color {
  &-light,
  &.light {
    color: var(--colorTextLight);
  }
  &-lighter,
  &.lighter {
    color: var(--colorTextLighter);
  }
  &-primary,
  &.primary {
    color: var(--colorPrimary);
  }
  &-secondary,
  &.secondary {
    color: var(--colorSecondary);
  }
  &-accent,
  &.accent {
    color: var(--colorAccent);
  }
  &-balance-positive,
  &.balance-positive {
    color: var(--colorBalancePositive);
  }
  &-balance-negative,
  &.balance-negative {
    color: var(--colorBalanceNegative);
  }
}

.headline-list {
  font-weight: 400;
  @apply text-base;
}

.overline-list {
  font-weight: 600;
  letter-spacing: 0.33px;
  text-transform: uppercase;
  color: var(--colorTextLight);
  @apply text-xs;
}

.overline-light-list {
  font-weight: 500;
  letter-spacing: 0.33px;
  text-transform: uppercase;
  color: var(--colorTextLighter);
  @apply text-xs;
}

.body-list {
  font-weight: 400;
  font-size: 0.875rem;
  @apply text-sm;
}

.headline-bold-list {
  font-weight: 600;
  color: var(--colorText);
  @apply text-base;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 2px);
  display: inline-block;
}
