.soft-checkbox {
  position: relative;
  font-size: 1rem;

  &-box {
    visibility: visible;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1.25rem;
    height: 1.25rem;
    border: var(--inputBorder);
    transition: all 0.33s ease;
    color: var(--inputText);
    background: var(--inputBackground);

    &:hover {
      background: var(--colorHover);
    }

    &.focus {
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    &.checked {
      @apply bg-primary;
    }

    &.readonly {
      box-shadow: none;
      @apply bg-primary-300;
    }

    &.disabled,
    &:disabled {
      box-shadow: none;
      @apply bg-primary-200 border-gray-300;
    }
  }

  &-indicator {
    visibility: visible;
    font-size: 0.825rem;
    position: absolute;
    top: 0.5rem;
    left: 0.225rem;
    margin-top: -0.225rem;
    @apply text-primary-contrast;
  }

  &-input {
    position: absolute;
    box-sizing: border-box;
    visibility: hidden;
    cursor: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}
