.soft-radio {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2rem;
  font-size: 1rem;

  &-bullet {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    top: 0.8rem;
    left: 0.3rem;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    @apply bg-primary-contrast;
  }

  &-box {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    border: var(--inputBorder);
    background: var(--inputBackground);

    &.disabled {
      @apply bg-primary-200;
    }

    &:hover {
      background: var(--colorHover);
    }

    &.checked {
      @apply bg-primary;
    }
  }

  &-input {
    visibility: hidden;
    position: absolute;
    cursor: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}
