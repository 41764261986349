@use '../abstracts/mixins';
@use '../abstracts/variables';

.soft-grid {
  @apply grid grid-cols-6 gap-3;
}

// Deprecated
.g {
  display: grid;
  width: 100%;

  &.gap {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}
.gc-right {
  justify-self: end;
}
.gc-center {
  justify-self: center;
}
.gr-bottom {
  align-self: end;
}
.gr-center {
  align-self: center;
}

.fill-available-space {
  width: 100% !important;
}

@include mixins.grid-template-x('gtc', 'grid-template-columns', 12, 1fr);
@include mixins.grid-template-x('gtr', 'grid-template-rows', 12);
@include mixins.grid-x('gc', 'grid-column', 12);
@include mixins.grid-x('gr', 'grid-row', 12);

@media only screen and (max-width: variables.$breakpointS) {
  @include mixins.grid-template-x('gtc-xs', 'grid-template-columns', 12, 1fr);
  @include mixins.grid-template-x('gtr-xs', 'grid-template-rows', 12);
  @include mixins.grid-x('gc-xs', 'grid-column', 12);
  @include mixins.grid-x('gr-xs', 'grid-row', 12);
}
@media only screen and (min-width: variables.$breakpointS) {
  @include mixins.grid-template-x('gtc-s', 'grid-template-columns', 12, 1fr);
  @include mixins.grid-template-x('gtr-s', 'grid-template-rows', 12);
  @include mixins.grid-x('gc-s', 'grid-column', 12);
  @include mixins.grid-x('gr-s', 'grid-row', 12);
}
@media only screen and (min-width: variables.$breakpointM) {
  @include mixins.grid-template-x('gtc-m', 'grid-template-columns', 12, 1fr);
  @include mixins.grid-template-x('gtr-m', 'grid-template-rows', 12);
  @include mixins.grid-x('gc-m', 'grid-column', 12);
  @include mixins.grid-x('gr-m', 'grid-row', 12);
}

@media only screen and (min-width: variables.$breakpointL) {
  @include mixins.grid-template-x('gtc-l', 'grid-template-columns', 12, 1fr);
  @include mixins.grid-template-x('gtr-l', 'grid-template-rows', 12);
  @include mixins.grid-x('gc-l', 'grid-column', 12);
  @include mixins.grid-x('gr-l', 'grid-row', 12);
}

@media only screen and (min-width: variables.$breakpointXL) {
  @include mixins.grid-template-x('gtc-xl', 'grid-template-columns', 12, 1fr);
  @include mixins.grid-template-x('gtr-xl', 'grid-template-rows', 12);
  @include mixins.grid-x('gc-xl', 'grid-column', 12);
  @include mixins.grid-x('gr-xl', 'grid-row', 12);
}
