.date-picker {
  width: auto;
  font-family: var(--fontFamily);
  z-index: 1001;


  .header-cell {
    text-align: center;
    border: 1px solid rgba(0,0,0,0.12);
  }

  .tbl-header {
    font-weight: bold;
    text-transform: uppercase;
  }

  table {
    margin: 0.75rem auto 0 auto;
  }

  .calender-cell {
    cursor: pointer;
    text-align: center;
    border: 1px solid rgba(0,0,0,0.12);
  }

  .selected {
    background: var(--colorSelected);
    font-weight: 700;
  }

  .today {
    background: var(--datePickerColorToday);
  }
}
