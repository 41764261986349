@mixin sizing($attribute, $name) {
  .#{$name}-0 {
    #{$attribute}: 0;
  }
  .#{$name}-10 {
    #{$attribute}: 10%;
  }
  .#{$name}-25 {
    #{$attribute}: 25%;
  }
  .#{$name}-50 {
    #{$attribute}: 50%;
  }
  .#{$name}-75 {
    #{$attribute}: 75%;
  }
  .#{$name}-100 {
    #{$attribute}: 100%;
  }
}

@include sizing('width', 'width');
@include sizing('height', 'height');
@include sizing('max-width', 'max-width');
@include sizing('max-height', 'max-height');
@include sizing('min-width', 'min-width');
@include sizing('min-height', 'min-height');

.border-box {
  box-sizing: border-box;
}
