/*.entity-picker-container {
  width: 100%;
  margin: 0;
  padding: 0;
  background: var(--panelBackground);
  z-index: 997;
  font-family: var(--fontFamily);
  border: var(--inputBorder);
  border-top-width: 0;
  overflow-y: auto;
  font-size: 1rem;
  box-sizing: border-box;
}

.tab-view {
  width: 100%;
  height: 49px;
  background: var(--panelBackground);
  display: flex;
  flex-direction: row;
  border-bottom: var(--inputBorder);
  box-sizing: border-box;
}

.indicator {
  @apply bg-primary;
  position: absolute;
  height: 2px;
  bottom: 0.675rem;
  width: 75%;
}

.tab-title {
  @apply text-primary;
  font-family: var(--fontFamily);
  font-weight: normal;
  font-size: 1rem;
  position: relative;
}

.view-container {
  width: 100%;
  height: 290.95px;
  content: '';
  background: var(--panelBackground);
  font-family: var(--fontFamily);
  padding: 0;
  overflow-y: auto;
  font-size: 1rem;
  box-sizing: border-box;
}

.soft-entity-picker {
  // position: absolute;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 290.95px;
  width: 100%;
  background: var(--panelBackground);
  font-family: var(--fontFamily);
  padding: 0;
  overflow-y: auto;
  font-size: 1rem;
  box-sizing: border-box;

  .detail-view {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .master-view {
    border-right: var(--inputBorder);
    font-family: var(--fontFamily);
    overflow-y: auto;
  }
}

.soft-entity-picker-mobile {
  position: absolute;
  display: flex;
  width: 100vw;
  background: var(--panelBackground);
  font-family: var(--fontFamily);
  border: var(--inputBorder);
  padding: 0;
  overflow-y: auto;
  font-size: 1rem;
  height: auto;
  max-height: 290.95px;
  box-sizing: border-box;

  .master-view {
    width: 100%;
    height: 100%;
    font-family: var(--fontFamily);
    padding: 1rem;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .hide {
    visibility: hidden;
  }

  .detail-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    background: var(--panelBackground);
  }

  .show {
    display: block;
    visibility: visible;
    z-index: 1000;
    transition: all 500ms ease;
  }

  .picker-back-button {
    color: var(--colorTextLight);
    margin-left: 1rem;
  }

  a {
    font-family: var(--fontFamily);
  }
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

.master-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.master-view::-webkit-scrollbar-thumb {
  background-color: #b7cae0;
}

.master-view::-webkit-scrollbar {
  width: 12px;
}

.master-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.master-view::-webkit-scrollbar-thumb {
  background-color: #b7cae0;
}
*/
