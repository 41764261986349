soft-capacitor-scanner-ui {
  display: none;
}
.scanner-hide {
  display: block;
}

body.qrscanner {
  background-color: transparent;
}
body.qrscanner soft-capacitor-scanner-ui {
  display: block;
}
body.qrscanner .scanner-hide {
  display: none;
}

