$backgroundColor: #e9eeee !default;
$backgroundColorHover: #b7cae0 !default;
$toggleBackgroundColor: #004f9f !default;
$toggleBackgroundColorHover: #003770 !default;
$toggleColor: #ffffff !default;
$userInfoBackgroundColor: #004f9f !default;
$userInfoColor: #ffffff !default;

soft-side-menu {
  background: var(--shellMenuBarBackground);

  .side-menu-toggle {
    color: var(--shellMenuBarHeaderText);
    background: var(--shellMenuBarHeaderBackground);
    height: 3rem;
    min-height: 3rem !important;
    cursor: pointer;
    &:hover {
      background-color: var(--shellMenuBarHeaderBackgroundHover);
      transition: all 0.15s ease-in-out;
    }
  }
  .side-menu-close {
    color: var(--shellMenuBarHeaderText);
    &:hover {
      background-color: var(--shellMenuBarHeaderBackgroundHover);
      transition: all 0.15s ease-in-out;
    }
  }
  .user-info {
    color: var(--shellMenuBarHeaderText);
    background: var(--shellMenuBarHeaderBackground);
    padding: 1rem 0;
  }
  .menu-version-info {
    color: var(--colorTextLighter);
    font-size: 0.5rem;
  }
}
