@use 'sass:meta';

/******************
**** Variables ****
******************/
$name: ':root' !default;

/******************
****** Fonts ******
******************/
$fontFamily: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
  helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif !default;
$font: 1rem #{$fontFamily} !default;

// TODO: Remove SCSS Variables (replaced with inline declarations in css properties)
// Check usages in various places (framework & apps) before

/******************
***** Colors ******
******************/
$background: #f8f8f8 !default;
$transparent: rgba(0, 0, 0, 0) !default;
$colorDisabled: #888888 !default;

//Primary
$colorPrimary: #004f9f !default;
$colorPrimary50: #f2f6fa !default;
$colorPrimary100: #e6edf5 !default;
$colorPrimary200: #bfd3e7 !default;
$colorPrimary300: #99b9d9 !default;
$colorPrimary400: #4d84bc !default;
$colorPrimary500: #004f9f !default;
$colorPrimary600: #00478f !default;
$colorPrimary700: #003b77 !default;
$colorPrimary800: #002f5f !default;
$colorPrimary900: #00274e !default;
$colorPrimaryContrast50: #000000 !default;
$colorPrimaryContrast100: #000000 !default;
$colorPrimaryContrast200: #000000 !default;
$colorPrimaryContrast300: #ffffff !default;
$colorPrimaryContrast400: #ffffff !default;
$colorPrimaryContrast500: #ffffff !default;
$colorPrimaryContrast600: #ffffff !default;
$colorPrimaryContrast700: #ffffff !default;
$colorPrimaryContrast800: #ffffff !default;
$colorPrimaryContrast900: #ffffff !default;

//Secondary
$colorSecondary: #b7cae0 !default;
$colorSecondary50: #fbfcfd !default;
$colorSecondary100: #f8fafc !default;
$colorSecondary200: #edf2f7 !default;
$colorSecondary300: #e2eaf3 !default;
$colorSecondary400: #cddae9 !default;
$colorSecondary500: #b7cae0 !default;
$colorSecondary600: #a5b6ca !default;
$colorSecondary700: #8998a8 !default;
$colorSecondary800: #6e7986 !default;
$colorSecondary900: #5a636e !default;
$colorSecondaryContrast50: #000000 !default;
$colorSecondaryContrast100: #000000 !default;
$colorSecondaryContrast200: #000000 !default;
$colorSecondaryContrast300: #000000 !default;
$colorSecondaryContrast400: #000000 !default;
$colorSecondaryContrast500: #000000 !default;
$colorSecondaryContrast600: #ffffff !default;
$colorSecondaryContrast700: #ffffff !default;
$colorSecondaryContrast800: #ffffff !default;
$colorSecondaryContrast900: #ffffff !default;

//Tertiary
$colorTertiary: #7164bd !default;
$colorTertiary50: #f8f7fc !default;
$colorTertiary100: #f1f0f8 !default;
$colorTertiary200: #dcd8ef !default;
$colorTertiary300: #c6c1e5 !default;
$colorTertiary400: #9c93d1 !default;
$colorTertiary500: #7164bd !default;
$colorTertiary600: #665aaa !default;
$colorTertiary700: #554b8e !default;
$colorTertiary800: #443c71 !default;
$colorTertiary900: #37315d !default;
$colorTertiaryContrast50: #000000 !default;
$colorTertiaryContrast100: #000000 !default;
$colorTertiaryContrast200: #000000 !default;
$colorTertiaryContrast300: #ffffff !default;
$colorTertiaryContrast400: #ffffff !default;
$colorTertiaryContrast500: #ffffff !default;
$colorTertiaryContrast600: #ffffff !default;
$colorTertiaryContrast700: #ffffff !default;
$colorTertiaryContrast800: #ffffff !default;
$colorTertiaryContrast900: #ffffff !default;

//Accent
$colorAccent: #32DBBE !default;
$colorAccent50: #CEF6F0 !default;
$colorAccent100: #BDF3EA !default;
$colorAccent200: #9AEDDF !default;
$colorAccent300: #77E7D4 !default;
$colorAccent400: #55E1C9 !default;
$colorAccent500: #32DBBE !default;
$colorAccent600: #20B59B !default;
$colorAccent700: #178573 !default;
$colorAccent800: #0F564A !default;
$colorAccent900: #072621 !default;


$colorAccentContrast50: #000000 !default;
$colorAccentContrast100: #000000 !default;
$colorAccentContrast200: #000000 !default;
$colorAccentContrast300: #000000 !default;
$colorAccentContrast400: #000000 !default;
$colorAccentContrast500: #000000 !default;
$colorAccentContrast600: #000000 !default;
$colorAccentContrast700: #ffffff !default;
$colorAccentContrast800: #ffffff !default;
$colorAccentContrast900: #ffffff !default;

//Danger
$colorDanger: #f43f5a !default;
$colorDanger50: #fff1f2 !default;
$colorDanger100: #ffe4e6 !default;
$colorDanger200: #fecdd3 !default;
$colorDanger300: #fda4a7 !default;
$colorDanger400: #fb7186 !default;
$colorDanger500: #f43f5a !default;
$colorDanger600: #e11d51 !default;
$colorDanger700: #be123a !default;
$colorDanger800: #9f1239 !default;
$colorDanger900: #881337 !default;
$colorDangerContrast50: #000000 !default;
$colorDangerContrast100: #000000 !default;
$colorDangerContrast200: #000000 !default;
$colorDangerContrast300: #ffffff !default;
$colorDangerContrast400: #ffffff !default;
$colorDangerContrast500: #ffffff !default;
$colorDangerContrast600: #ffffff !default;
$colorDangerContrast700: #ffffff !default;
$colorDangerContrast800: #ffffff !default;
$colorDangerContrast900: #ffffff !default;

//Messages
$colorError: rgb(164, 38, 44) !default;
$colorErrorBackground: rgba(245, 135, 145, 0.2) !default;
$colorDangerBackground: rgba(245, 135, 145, 0.2) !default;
$colorWarning: rgb(175, 103, 0) !default;
$colorWarningBackground: rgba(255, 200, 10, 0.2) !default;
$colorSuccess: rgb(16, 124, 16) !default;
$colorSuccessBackground: rgba(95, 210, 85, 0.2) !default;
$colorInfo: rgb(106, 117, 120) !default;
$colorInfoBackground: rgb(243, 242, 241) !default;

/******************
*** Typography ***
******************/
$colorText: rgba(0, 0, 0, 0.9) !default;
$colorTextLight: rgba(0, 0, 0, 0.5) !default;
$colorTextLighter: rgba(0, 0, 0, 0.2) !default;

$colorLink: rgba(119, 140, 174, 0.87) !default;
$colorTitle: var(--colorText) !default;
$colorSubtitle: var(--colorTextLight) !default;
$colorOverline: #787fbc !default;

$colorBalancePositive: rgba(0, 0, 0, 0.85) !default;
$colorBalanceNegative: rgba(255, 0, 0, 0.85) !default;

/******************
**** Components ****
******************/
$colorSurface: #ffffff !default;

$colorSelected: #cddae9 !default;
$colorHover: rgba(0, 0, 0, 0.075) !default;

//Input
$inputBackground: var(--colorSurface) !default;
$inputText: var(--colorText) !default;
$inputPlaceholder: #999a9b !default;
$inputBorder: 1px solid #b7cae0 !default;
$inputBorderRadius: 0 !default;

//Panel
$panelBackground: var(--colorSurface) !default;
$panelText: var(--colorText) !default;
$panelBorder: none !default;
$panelBorderRadius: 0 !default;

//Chip
$chipColor: rgba(0, 0, 3, 0.08) !default;
$chipColorText: var(--colorText) !default;
$chipBorderRadius: 24px !default;

//DatePicker
$datePickerColorLastMonth: #b4b4b4 !default;
$datePickerColorToday: #d4d4d4 !default;

//FileInput
$fileInputBorder: 4.5px dashed var(--colorSecondary) !default;

//LoadingBar
$loadingBarColor: linear-gradient(
  to right,
  #b7cae000,
  #b7cae088,
  #b7cae0aa,
  #b7cae0dd,
  #b7cae0aa,
  #b7cae088,
  #b7cae000
) !default;

//Scroll
$scrollBackground: #fafafa !default;
$scrollIndicator: var(--colorPrimary200) !default;

//Skeleton
$skeletonColor: rgba(255, 255, 255, 0.4) !default;
$skeletonBackground: rgba(235, 235, 235) !default;

//Spinner
$spinnerColor: #171717 !default;
$spinnerBackground: #d2d2d2 !default;

//Tab
$tabIndicator: var(--colorPrimary) !default;

//ToolTip
$toolTipBorder: 1px solid #b7cae0 !default;

//Table
$tableBorder: 1px solid #b7cae0 !default;
$tableBackground: var(--panelBackground) !default;

$tableHeaderBackground: var(--panelBackground) !default;
$tableHeaderColor: $colorText !default;
$tableHeaderSeparator: none !default;

$tableRowBackground: var(--panelBackground) !default;
$tableRowBackgroundStriped: #ebebeb !default;
$tableRowColor: var(--colorText) !default;
$tableRowSeparator: none !default;

$tableFooterSeparator: 1px solid rgba(0, 0, 0, 0.15) !default;
$tableFooterBackground: rgba(0, 0, 0, 0.03) !default;
$tableFooterColor: var(--colorText) !default;

$tableCellHeight: auto !default;
$tableCellMinHeight: 18px !default;
$tableCellPadding: 0.25rem 0.75rem !default;
$tableCellVerticalAlign: middle !default;
$tableCellBorder: none !default;

//ProgressBar
$progressColor: rgba(112, 150, 193, 0.2) !default;

//Modal
$modalOverlayBackground: rgba(0, 0, 0, 0.4) !default;

/******************
****** Misc ******
******************/

$separator: 1px solid rgba(0, 0, 0, 0.1) !default;

/*
 * -----------------
 * Light (default) Theme
 * -----------------
 */
#{$name} {
  --colorBorderTableOuter: #9ea7b2;
  --colorBorderTableInner: #e7e7e7;

  /******************
  ****** Fonts ******
  ******************/
  --font: #{meta.inspect($font)};
  --fontFamily: #{meta.inspect($fontFamily)};

  /******************
  ***** Colors ******
  ******************/
  --background: #f8f8f8;
  --transparent: rgba(0, 0, 0, 0);
  --colorDisabled: #d2d2d2;
  --colorReadonly: #e5e7eb;

  // TODO
  --colorHeaderBackground: #000000;
  --colorFooterBackground: #000000;

  // TODO
  --colorTopBarBackground: #000000;
  --colorTopBar: #FFFFFF;

  // TODO
  // SURFACE COLOR
  // Things like cards, the background of form controls, menus etc.
  --colorSurface: #FFFFFF;
  --colorSurfaceSecondary: #f5f5f5;
  --colorSurfaceTertiary: #ebebed;

  // TODO
  // BACKDROP COLOR
  --colorBackdrop: rgba(0, 0, 0, 0.30);

  //Primary
  --colorPrimary: #0177BF;
  --colorPrimary50: #CCE4F2;
  --colorPrimary100: #A6CFE9;
  --colorPrimary200: #80BBDF;
  --colorPrimary300: #5AA7D5;
  --colorPrimary400: #3492CC;
  --colorPrimary500: #0177BF;
  --colorPrimary600: #0165A2;
  --colorPrimary700: #015386;
  --colorPrimary800: #014169;
  --colorPrimary900: #00304C;
  --colorPrimarySelection: #E8EFF6;

  --colorPrimaryContrast: #FFFFFF;
  --colorPrimaryContrast50: #000000;
  --colorPrimaryContrast100: #000000;
  --colorPrimaryContrast200: #000000;
  --colorPrimaryContrast300: #000000;
  --colorPrimaryContrast400: #FFFFFF;
  --colorPrimaryContrast500: #FFFFFF;
  --colorPrimaryContrast600: #FFFFFF;
  --colorPrimaryContrast700: #FFFFFF;
  --colorPrimaryContrast800: #FFFFFF;
  --colorPrimaryContrast900: #FFFFFF;

  //Secondary
  --colorSecondary: #b7cae0;
  --colorSecondary50: #fbfcfd;
  --colorSecondary100: #f8fafc;
  --colorSecondary200: #edf2f7;
  --colorSecondary300: #e2eaf3;
  --colorSecondary400: #cddae9;
  --colorSecondary500: #b7cae0;
  --colorSecondary600: #a5b6ca;
  --colorSecondary700: #8998a8;
  --colorSecondary800: #6e7986;
  --colorSecondary900: #5a636e;
  --colorSecondaryContrast: #000000;
  --colorSecondaryContrast50:#000000;
  --colorSecondaryContrast100: #000000;
  --colorSecondaryContrast200: #000000;
  --colorSecondaryContrast300: #000000;
  --colorSecondaryContrast400: #000000;
  --colorSecondaryContrast500: #000000;
  --colorSecondaryContrast600: #FFFFFF;
  --colorSecondaryContrast700: #FFFFFF;
  --colorSecondaryContrast800: #FFFFFF;
  --colorSecondaryContrast900: #FFFFFF;

  //Tertiary
  --colorTertiary: #7164bd;
  --colorTertiary50: #f8f7fc;
  --colorTertiary100: #f1f0f8;
  --colorTertiary200: #dcd8ef;
  --colorTertiary300: #c6c1e5;
  --colorTertiary400: #9c93d1;
  --colorTertiary500: #7164bd;
  --colorTertiary600: #665aaa;
  --colorTertiary700: #554b8e;
  --colorTertiary800: #443c71;
  --colorTertiary900: #37315d;
  --colorTertiaryContrast: #000000;
  --colorTertiaryContrast50: #000000;
  --colorTertiaryContrast100: #000000;
  --colorTertiaryContrast200: #000000;
  --colorTertiaryContrast300: #FFFFFF;
  --colorTertiaryContrast400: #FFFFFF;
  --colorTertiaryContrast500: #FFFFFF;
  --colorTertiaryContrast600: #FFFFFF;
  --colorTertiaryContrast700: #FFFFFF;
  --colorTertiaryContrast800: #FFFFFF;
  --colorTertiaryContrast900: #FFFFFF;

  //Accent
  --colorAccent: #32DBBE;
  --colorAccent50: #CEF6F0;
  --colorAccent100: #BDF3EA;
  --colorAccent200: #9AEDDF;
  --colorAccent300: #77E7D4;
  --colorAccent400: #55E1C9;
  --colorAccent500: #32DBBE;
  --colorAccent600: #20B59B;
  --colorAccent700: #178573;
  --colorAccent800: #0F564A;
  --colorAccent900: #072621;
  --colorAccentSelection: #c5e8e3;

  --colorAccentContrast: #000000;
  --colorAccentContrast50: #000000;
  --colorAccentContrast100: #000000;
  --colorAccentContrast200: #000000;
  --colorAccentContrast300: #000000;
  --colorAccentContrast400: #000000;
  --colorAccentContrast500: #000000;
  --colorAccentContrast600: #000000;
  --colorAccentContrast700: #FFFFFF;
  --colorAccentContrast800: #FFFFFF;
  --colorAccentContrast900: #FFFFFF;

  //Danger
  --colorDanger: #f43f5a;
  --colorDanger50: #fff1f2;
  --colorDanger100: #ffe4e6;
  --colorDanger200: #fecdd3;
  --colorDanger300: #fda4a7;
  --colorDanger400: #fb7186;
  --colorDanger500: #f43f5a;
  --colorDanger600: #e11d51;
  --colorDanger700: #be123a;
  --colorDanger800: #9f1239;
  --colorDanger900: #881337;
  --colorDangerContrast: #FFFFFF;
  --colorDangerContrast50: #000000;
  --colorDangerContrast100: #000000;
  --colorDangerContrast200: #000000;
  --colorDangerContrast300: #FFFFFF;
  --colorDangerContrast400: #FFFFFF;
  --colorDangerContrast500: #FFFFFF;
  --colorDangerContrast600: #FFFFFF;
  --colorDangerContrast700: #FFFFFF;
  --colorDangerContrast800: #FFFFFF;
  --colorDangerContrast900: #FFFFFF;

  //Promotion
  --colorPromotion: #f43f5a;
  --colorPromotion50: #fff1f2;
  --colorPromotion100: #ffe4e6;
  --colorPromotion200: #fecdd3;
  --colorPromotion300: #fda4a7;
  --colorPromotion400: #fb7186;
  --colorPromotion500: #f43f5a;
  --colorPromotion600: #e11d51;
  --colorPromotion700: #be123a;
  --colorPromotion800: #9f1239;
  --colorPromotion900: #881337;
  --colorPromotionContrast: #FFFFFF;
  --colorPromotionContrast50: #000000;
  --colorPromotionContrast100: #000000;
  --colorPromotionContrast200: #000000;
  --colorPromotionContrast300: #FFFFFF;
  --colorPromotionContrast400: #FFFFFF;
  --colorPromotionContrast500: #FFFFFF;
  --colorPromotionContrast600: #FFFFFF;
  --colorPromotionContrast700: #FFFFFF;
  --colorPromotionContrast800: #FFFFFF;
  --colorPromotionContrast900: #FFFFFF;

  //Error
  --colorError: #f43f5a;
  --colorError50: #fff1f2;
  --colorError100: #ffe4e6;
  --colorError200: #fecdd3;
  --colorError300: #fda4a7;
  --colorError400: #fb7186;
  --colorError500: #f43f5a;
  --colorError600: #e11d51;
  --colorError700: #be123a;
  --colorError800: #9f1239;
  --colorError900: #881337;
  --colorErrorContrast: #FFFFFF;
  --colorErrorContrast50: #000000;
  --colorErrorContrast100: #000000;
  --colorErrorContrast200: #000000;
  --colorErrorContrast300: #FFFFFF;
  --colorErrorContrast400: #FFFFFF;
  --colorErrorContrast500: #FFFFFF;
  --colorErrorContrast600: #FFFFFF;
  --colorErrorContrast700: #FFFFFF;
  --colorErrorContrast800: #FFFFFF;
  --colorErrorContrast900: #FFFFFF;

  // WARNING COLOR
  --colorWarning100: #ffedd7;
  --colorWarning200: #fce3c6;
  --colorWarning300: #ffd096;
  --colorWarning400: #fcc27e;
  --colorWarning500: #ffae49;
  --colorWarning600: #f6a036;
  --colorWarning700: #d77e11;
  --colorWarning800: #b46300;
  --colorWarning900: #884c00;
  --colorWarningContrast100: #000000;
  --colorWarningContrast200: #000000;
  --colorWarningContrast300: #000000;
  --colorWarningContrast400: #FFFFFF;
  --colorWarningContrast500: #FFFFFF;
  --colorWarningContrast600: #FFFFFF;
  --colorWarningContrast700: #FFFFFF;
  --colorWarningContrast800: #FFFFFF;
  --colorWarningContrast900: #FFFFFF;

  // INFO COLOR
  --colorInfo100: #d9edf6;
  --colorInfo200: #d3edf8;
  --colorInfo300: #a4c8e7;
  --colorInfo400: #93c2e0;
  --colorInfo500: #62a6d5;
  --colorInfo600: #5cbbff;
  --colorInfo700: #3591d2;
  --colorInfo800: #1f74b2;
  --colorInfo900: #0f4669;
  --colorInfoContrast100: #000000;
  --colorInfoContrast200: #000000;
  --colorInfoContrast300: #000000;
  --colorInfoContrast400: #000000;
  --colorInfoContrast500: #000000;
  --colorInfoContrast600: #FFFFFF;
  --colorInfoContrast700: #FFFFFF;
  --colorInfoContrast800: #FFFFFF;
  --colorInfoContrast900: #FFFFFF;

  // SUCCESS COLOR
  --colorSuccess100: #d6ffdf;
  --colorSuccess200: #c6ffd4;
  --colorSuccess300: #a9f3ba;
  --colorSuccess400: #61d97a;
  --colorSuccess500: #50c969;
  --colorSuccess600: #39b956;
  --colorSuccess700: #1a9b36;
  --colorSuccess800: #0c8326;
  --colorSuccess900: #036719;
  --colorSuccessContrast100: #000000;
  --colorSuccessContrast200: #000000;
  --colorSuccessContrast300: #000000;
  --colorSuccessContrast400: #000000;
  --colorSuccessContrast500: #FFFFFF;
  --colorSuccessContrast600: #FFFFFF;
  --colorSuccessContrast700: #FFFFFF;
  --colorSuccessContrast800: #FFFFFF;
  --colorSuccessContrast900: #FFFFFF;

  --colorBorder: #e5e7eb;
  --colorBorderLight: #e9ecf2;
  --colorTopContainerBackground: var(--colorSurface);

  //Messages
  --colorError: rgb(164, 38, 44);
  --colorErrorBackground: rgba(245, 135, 145, 0.2);
  --colorDangerBackground: rgba(245, 135, 145, 0.2);
  --colorWarning: rgb(175, 103, 0);
  --colorWarningBackground: rgba(255, 200, 10, 0.2);
  --colorSuccess: rgb(16, 124, 16);
  --colorSuccessBackground: rgba(95, 210, 85, 0.2);
  --colorInfo: rgb(106, 117, 120);
  --colorInfoBackground: rgb(243, 242, 241);

  /******************
  *** Typography ***
  ******************/
  --colorText: rgba(0, 0, 0, 0.9);
  --colorTextLight: rgba(0, 0, 0, 0.5);
  --colorTextLighter: rgba(0, 0, 0, 0.2);

  --colorLink: rgba(119, 140, 174, 0.87);
  --colorTitle: var(--colorText);
  --colorSubtitle: var(--colorTextLight);
  --colorOverline: var(--colorAccent700);

  --colorBalancePositive: rgba(0, 0, 0, 0.85);
  --colorBalanceNegative: rgba(255, 0, 0, 0.85);

  /******************
  **** Components ****
  ******************/

  --colorSelected: #CAEBFF;
  --colorHover: rgba(0, 0, 0, 0.075);

  //Input
  --inputBackground: var(--colorSurface);
  --inputText: var(--colorText);
  --inputPlaceholder: #999a9b;
  --inputBorder: 1px solid #b7cae0;
  --inputBorderRadius: 0;

  //Panel
  --panelBackground: var(--colorSurface);
  --panelText: var(--colorText);
  --panelBorderColor: var(--colorBorder);
  --panelBorder: none;
  --panelBorderRadius: 0;

  // Card
  --cardBoxShadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --cardBoxShadowHard: 0 1px 3px 0 rgb(0 0 0 / 0.5), 0 1px 2px -1px rgb(0 0 0 / 0.5);

  //Chip
  --chipColor: rgba(0, 0, 3, 0.08);
  --chipColorText: var(--colorText);
  --chipBorderRadius: 24px;

  //DatePicker
  --datePickerColorLastMonth: #b4b4b4;
  --datePickerColorToday: #d4d4d4;

  //FileInput
  --fileInputBorder: 4.5px dashed var(--colorSecondary);

  //LoadingBar
  --loadingBarColor: linear-gradient(
      to right,
      #b7cae000,
      #b7cae088,
      #b7cae0aa,
      #b7cae0dd,
      #b7cae0aa,
      #b7cae088,
      #b7cae000
  );

  //Scroll
  --scrollBackground: var(--colorSurfaceTertiary);
  --scrollIndicator: var(--colorPrimary200);

  //Skeleton
  --skeletonColor: rgba(255, 255, 255, 0.4);
  --skeletonColorBackground: #dddddd;

  //Spinner
  --spinnerColor: #171717;
  --spinnerBackground: #d2d2d2;

  //Tab
  --tabIndicator: (--colorPrimary);

  // ToolTip
  --toolTipBorder: 1px solid #b7cae0;

  //Table
  --tableBorder: 1px solid #b7cae0;
  --tableBackground: var(--panelBackground);

  --tableHeaderSeparator: none;
  --tableHeaderBackground: var(--panelBackground);
  --tableHeaderColor: var(--colorText);

  --tableRowBackground: var(--panelBackground) ;
  --tableRowBackgroundStriped: #ebebeb;
  --tableRowColor: var(--colorText);
  --tableRowSeparator: none;

  --tableFooterBackground: rgba(0, 0, 0, 0.03);
  --tableFooterColor: var(--colorText);
  --tableFooterSeparator: 1px solid rgba(0, 0, 0, 0.15);

  --tableCellHeight: auto;
  --tableCellMinHeight: 18px;
  --tableCellPadding: 0.25rem 0.75rem;
  --tableCellVerticalAlign: middle;
  --tableCellBorder: none;

  //Progress
  --progressColor: rgba(112, 150, 193, 0.2);

  //Modal
  // TODO: Replace with colorBackdrop
  --modalOverlayBackground: rgba(0, 0, 0, 0.4);

  /******************
  ****** Misc ******
  ******************/
  --separator: 1px solid var(--colorBorder);

  // old
  --sectionHeaderColor: rgba(1, 1, 7, 0.33);
  --sectionHeaderBorderColor: rgba(0, 0, 0, 0.15);
}
