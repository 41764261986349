soft-message-bar-container {
  width: 100%;
}

.soft-message-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  padding: 12px;
  font-family: var(--fontFamily);
  font-size: 0.875rem;
  word-break: break-word;
  border-radius: 10px;
  border: 1px solid;

  &-info,
  &.info {
    background: var(--colorInfoBackground);
    border-color: var(--colorInfo);
    .soft-message-bar-icon {
      color: var(--colorInfo);
      margin-right: 10px;
    }
  }
  &-warning,
  &.warning {
    background: var(--colorWarningBackground);
    border-color: var(--colorWarning);
    .soft-message-bar-icon {
      color: var(--colorWarning);
      margin-right: 10px;
    }
  }
  &-error,
  &.error {
    background: var(--colorErrorBackground);
    border-color: var(--colorError);
    .soft-message-bar-icon {
      color: var(--colorError);
      margin-right: 10px;
    }
  }
  &-success,
  &.success {
    background: var(--colorSuccessBackground);
    border-color: var(--colorSuccess);
    .soft-message-bar-icon {
      color: var(--colorSuccess);
      margin-right: 10px;
    }
  }
  &-loading,
  &.loading {
    .soft-message-bar-icon {
      margin-right: 10px;
    }
  }
  &-progress,
  &.progress {
    transition: all 0.2s linear;

    .soft-message-bar-icon {
      margin-right: 10px;
    }

    @for $i from 1 through 100 {
      &.progress-#{$i} {
        transition: all 0.2s linear;
        background: linear-gradient(
          90deg,
          var(--progressColor) $i * 1%,
          var(--transparent) $i * 1% + 1,
          var(--transparent) (101% - ($i * 1))
        );
      }
    }
  }
}

.msg-bar-wrapper {
  // margin: 6px 0 6px 0;
  background: var(--panelBackground);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
