@use '../abstracts/mixins';

soft-toggle-switch {
  display: flex;
  align-items: center;

  &:first-child {
    @include mixins.userSelect(none);
  }
}

.soft-toggle-switch {
  @include mixins.userSelect(none);

  width: 2.75rem;
  height: 1.5rem;
  box-sizing: content-box;
  background: var(--inputBackground);
  border: var(--inputBorder);
  border-radius: 1.5rem;
  box-shadow: inset 1px 1px 4px rgba(7, 8, 9, 0.10),
    inset 0 0 1.5rem rgba(7, 8, 9, 0.125),
    inset 1px 1px 5px rgba(0, 0, 0, 0.075);
  transition: transform 0.175s ease-out;
  position: relative;
  display: block;

  &-checked,
  &.checked {
    background-color: var(--colorPrimary);

    &:hover {
      background-color: var(--colorPrimary600) !important;
    }
  }

  &-large,
  &.large {
    width: 3.5rem;
    height: calc(2rem - 2px);
    border-radius: 2.25rem;
  }

  &-handle,
  &.handle {
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 100%;
    transition: all 0.175s ease;
    background-color: var(--colorPrimaryContrast);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    transform: scale(0.95);

    &-checked,
    &.checked {
      left: 100%;
      transform: translateX(calc(-100%)) scale(0.95);

      &-large,
      &.large {
        left: 100% !important;
        transform: translateX(-100%);
      }
    }

    &:active {
      width: 1.6rem;
    }

    &-large,
    &.large {
      top: 0;
      left: 0;
      width: calc(2rem - 2px);
      height: calc(2rem - 2px);

      &:active {
        width: 2.125rem;
      }
    }
  }

  &:hover {
    background-color: var(--colorHover);
  }

  &-disabled,
  &.disabled {
    .soft-toggle-switch-handle {
      background-color: var(--chipColor);
    }

  }
  &-readonly,
  &.readonly {
    .soft-toggle-switch-handle {
      background-color: var(--chipColor);
    }
  }
}
