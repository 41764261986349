@use '../abstracts/variables';

.soft-modal-content {
  @apply border;
  background-color: var(--panelBackground);
  max-width: 100vw;

  border-color: var(--colorBorder);

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;

  .no-sheet {
    position: relative;
    margin: 5vh auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  @media only screen and (min-width: variables.$breakpointS) {
    @apply p-5;
    max-width: 80vw;

    position: relative;
    margin: 5vh auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
  @media only screen and (min-width: variables.$breakpointS) {
  }
  @media only screen and (min-width: variables.$breakpointL) {
    max-width: 60vw;
  }

  &.wide {
    @media only screen and (min-width: variables.$breakpointS) {
      max-width: 95vw;
    }
  }
}
