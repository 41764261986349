.soft-card {
  background: var(--colorSurface);
  padding: 0.625rem 0.825rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  box-shadow: var(--cardBoxShadow);

  &.rounded,
  &-rounded {
    border-radius: 0.25rem;

    &-xl,
    &.xl {
      @apply rounded-2xl;
    }

    &-l,
    &.l {
      @apply rounded-lg;
    }

    &-m,
    &.m {
      @apply rounded-md;
    }

    &-s,
    &.s {
      @apply rounded-sm;
    }

    &-xs,
    &.xs {
      border-radius: 1px;
    }
  }

  &.hard {
    box-shadow: var(--cardBoxShadowHard);
  }

  .selected {
    border: var(--colorPrimary) solid 2px;
  }
}

// Selected Blue Border
li.selected > * > .soft-card:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 7px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  @apply bg-primary;
}
