soft-input,
soft-number-input,
soft-date-input,
soft-duration-input,
soft-time-input,
soft-duration-input,
soft-entity-input,
soft-entity-list-input,
soft-entity-query-input,
soft-select,
soft-combo-box,
soft-multiline-input,
soft-password-input,
soft-multi-select,
soft-date-range-from-input,
soft-date-range-to-input, {
  width: 100%;
  height: 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: var(--inputBorder);
  color: var(--inputText);
  background-color: var(--inputBackground);
  margin: 0;
  padding: 0.425rem 0.75rem;

  @apply text-sm;

  &.readonly {
    @apply bg-readonly;

    input:read-only {
      @apply bg-readonly;
      cursor: default;
    }
  }

  &.error, .error>& {
    border-color: var(--colorError500);
  }
  &.warning, .warning>& {
    border-color: var(--colorWarning500);
  }
  &.success, .success>& {
    border-color: var(--colorSuccess500);
  }
  &.info, .info>& {
    border-color: var(--colorInfo500);
  }

  &.align-right {
    text-align: right;
    & > input {
      text-align: inherit;
    }
  }
}

soft-multiline-input {
  min-height: 2rem;
  height: auto;

  & > textarea {
    font-family: var(--fontFamily);
    white-space: pre-line;
  }
}

soft-multi-select, soft-entity-list-input {
  min-height: 2rem;
  height: auto;
}

soft-input[readonly='true'],
soft-input[ng-reflect-readonly='true'],
soft-number-input[readonly='true'],
soft-number-input[ng-reflect-readonly='true'],
soft-date-input[readonly='true'],
soft-date-input[ng-reflect-readonly='true'],
soft-duration-input[readonly='true'],
soft-duration-input[ng-reflect-readonly='true'],
soft-time-input[readonly='true'],
soft-time-input[ng-reflect-readonly='true'],
soft-duration-input[readonly='true'],
soft-duration-input[ng-reflect-readonly='true'],
soft-entity-input[readonly='true'],
soft-entity-input[ng-reflect-readonly='true'],
soft-entity-list-input[readonly='true'],
soft-entity-list-input[ng-reflect-readonly='true'],
soft-combo-box[readonly='true'],
soft-combo-box[ng-reflect-readonly='true'],
soft-select[readonly='true'],
soft-select[ng-reflect-readonly='true'],
soft-multiline-input[readonly='true'],
soft-multiline-input[ng-reflect-readonly='true'],
soft-password-input[readonly='true'],
soft-password-input[ng-reflect-readonly='true'],
soft-multi-select[readonly='true'],
soft-multi-select[ng-reflect-readonly='true'] {
  @apply bg-readonly;

  input:read-only {
    @apply bg-readonly;
    cursor: default;
  }
}

input[type='date'], .soft-input[type='time'] {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}



// Obsolete
.soft-input {
  box-shadow: none;
  border: var(--inputBorder);
  font-size: 1rem;
  padding: 0.425rem 0.75rem;
  width: 100%;
  box-sizing: border-box;
  color: var(--inputText);
  background: var(--inputBackground);

  &.readonly {
    @apply bg-readonly;

    input:read-only {
      @apply bg-readonly;
      cursor: default;
    }
  }

  &.error {
    border-color: var(--colorError);
  }

  .placeholder {
    @apply text-sm;
    color: var(--inputPlaceholder);
    font-family: var(--fontFamily);
    opacity: 1;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--inputBackground) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--inputText) !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--inputPlaceholder);
  font-family: var(--fontFamily);
  @apply text-sm;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--inputPlaceholder);
  font-family: var(--fontFamily);
  @apply text-sm;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--inputPlaceholder);
  font-family: var(--fontFamily);
  @apply text-sm;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--inputPlaceholder);
  font-family: var(--fontFamily);
  @apply text-sm;
}

/* BUGFIX iOS Safari This is required in order to have the native iOS time/date-picker working */
input[type="time"], input[type="date"] {
  visibility: visible;
  min-height: 2rem !important;
}

input::-webkit-date-and-time-value {
  margin-top: 0rem;
  text-align: left;
  width: 100%;
}
/* END */

input[type='reset'] {
  margin: 0;
  width: auto;
  overflow: visible;
  border-radius: 0;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: none;
  text-align: inherit;
  box-shadow: none;
  color: var(--colorLink);
  background-color: transparent;
  padding: 0.625rem 1rem 0.5rem 1rem;
  font-family: var(--buttonFontFamily);
  font-weight: 700;
  transition: all 0.33s ease;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: underline;
    transition: all 0.3s ease;
  }
}

/** MDE Input (Larger Font) **/
.soft-mde-input {
  @apply text-base h-12;

  ::-webkit-input-placeholder {
    @apply text-lg;
  }
  ::-moz-placeholder {
    @apply text-lg;
  }
  :-ms-input-placeholder {
    @apply text-lg;
  }
  :-moz-placeholder {
    @apply text-lg;
  }
}
