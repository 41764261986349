.menu {
  &.menu-item:not(.separator):not(.title):not(.disabled) {
    background: var(--shellMenuItemBackground);
    color: var(--shellMenuItemText);
    cursor: pointer;
    outline: none;
    padding: 10px 0;
    align-items: center;
    &:hover {
      background-color: var(--shellMenuItemBackgroundHover) !important;
      transition: all 0.15s ease-in-out;
    }
    &.sub-menu-item {
      color: var(--shellSubMenuItemText);
      background: var(--shellSubMenuItemBackground);
      &:hover {
        background-color: var(--shellSubMenuItemBackgroundHover) !important;
      }
      &.disabled {
        color: var(--shellSubMenuItemTextDisabled);
        background: var(--shellSubMenuItemBackgroundDisabled);
      }
      &.active {
        color: var(--shellSubMenuItemTextActive);
        background: var(--shellSubMenuItemBackgroundActive);
        &:before {
          background-color: var(--shellSubMenuItemActiveIndicator);
        }
      }
    }
  }

  &.separator {
    width: 80%;
    margin: 5px auto;
    box-sizing: border-box;
    border-top: var(--shellMenuSeparator);
    outline: none;
  }

  &.title {
    width: 100%;
    display: flex;
    outline: none;
    color: var(--shellMenuTitleText);
    box-sizing: border-box;

    padding: 15px 0 0 27px;

    font-weight: 700;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  &.disabled {
    color: var(--shellMenuItemTextDisabled);
    background: var(--shellMenuItemBackgroundDisabled);
    user-select: none;
    outline: none;
    padding: 10px 0;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  &.active {
    position: relative;
    color: var(--shellMenuItemTextActive) !important;
    background: var(--shellMenuItemBackgroundActive) !important;
    &:before {
      position: absolute;
      left: 0;
      content: '';
      display: inline-block;
      width: 5px;
      height: 100%;
      background-color: var(--shellMenuItemActiveIndicator) !important;
    }
  }
}
