.soft-top-container {
  @apply flex flex-col z-10 -top-4 border-b px-4 -mt-4 pb-3 pt-5;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  background-color: var(--colorTopContainerBackground);
}

.soft-bottom-container {
  @apply flex flex-col z-10 border-b;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  background-color: var(--colorTopContainerBackground);
}
