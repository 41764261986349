$fontFamily: var(--validationFontFamily) !default;
$infoIconColor: var(--validationColorIconInfo) !default;
$infoBackgroundColor: var(--validationColorBackgroundIconInfo) !default;
$warningIconColor: var(--validationColorIconWarning) !default;
$warningBackgroundColor: var(--validationColorBackgroundIconWarning) !default;
$errorIconColor: var(--validationColorIconError) !default;
$errorBackgroundColor: var(--validationColorBackgroundIconError) !default;
$successIconColor: var(--validationColorIconSuccess) !default;
$successBackgroundColor: var(--validationColorBackgroundIconSuccess) !default;

$propertyInfoColor: var(--validationColorPropertyInfo) !default;
$propertyWarningColor: var(--validationColorPropertyWarning) !default;
$propertyErrorColor: var(--validationColorPropertyError) !default;
$propertySuccessColor: var(--validationColorPropertySuccess) !default;

.soft-entity-validation {

  font-family: var(--fontFamily);

  .soft-validation {
    &-info {
      background-color: var(--colorPrimary100);
      .soft-validation-icon {
        color: var(--colorPrimary700);
        margin-right: 10px;
      }
    }

    &-warning {
      background-color: var(--colorWarningBackground);
      .soft-validation-icon {
        color: var(--colorWarning);
        margin-right: 10px;
      }
    }

    &-error {
      background-color: var(--colorErrorBackground);
      .soft-validation-icon {
        color: var(--colorError);
        margin-right: 10px;
      }
    }

    &-success {
      background-color: var(--colorSuccessBackground);
      .soft-validation-icon {
        color: var(--colorSuccess);
        margin-right: 10px;
      }
    }
  }
}
.soft-property-validation {
  font-family: var(--fontFamily);
  font-size: 0.675rem;

  .soft-validation {
    &-info {
      color: var(--colorPrimary700);
      .soft-validation-icon {
        margin-right: 10px;
      }
    }
    &-warning {
      color: var(--colorWarning);
      .soft-validation-icon {
        margin-right: 10px;
      }
    }
    &-error {
      color: var(--colorError);
      .soft-validation-icon {
        margin-right: 10px;
      }
    }
    &-success {
      color: var(--colorSuccess);
      .soft-validation-icon {
        margin-right: 10px;
      }
    }
  }
}
