@use '../abstracts/mixins';

soft-file-input {
  width: 100%;

  .common-input-view {
    width: 7.5rem;
    height: 8rem;
    background-color: var(--colorSurface);
    border: var(--inputBorder);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-description {
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 100;
      width: 200%;
      font-size: 0.825rem;
      margin: 0 0.75rem;
    }

    &:hover {
      background: var(--colorHover);
    }
  }

  .grid-value-image {
    width: 7.5rem;
    height: 8rem;
    background-color: var(--colorSurface);
    border: var(--inputBorder);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-description {
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 100;
      width: 200%;
      font-size: 0.825rem;
      margin: 0 0.75rem;
    }

    &:hover {
      background: var(--colorHover);
    }
  }

  .grid-value-view {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 98;

    .icon {
      position: absolute;
      right: 6px;
      top: 6px;
      z-index: 102;

      &.delete {
        color: var(--colorDanger);
        cursor: pointer;
      }

      &.succeeded {
        color: var(--colorSuccess);
      }
    }

    &-center {
      justify-content: center;
      align-items: center;
      color: var(--colorText);
      z-index: 100;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: var(--inputText);
        background: var(--inputBackground);
        opacity: 0.4;
        z-index: 99;
        @include mixins.userSelect(none);
      }
    }

    .progress-bar {
      position: absolute;
      height: 2px;
      bottom: 20px;
      z-index: 100;
      background-color: var(--colorTextLighter);
      left: 0;
    }
  }
}

soft-drag-and-drop-file-input {
  width: 100%;
}

.soft-file-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  &-item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    &:hover {
      background-color: var(--colorHover);
    }

    &-description {
      font-weight: bold;
    }

    &-filesize {
      font-size: 0.775rem;
    }

    &-processing {
      padding: 0.75rem;
    }

    &-succeeded {
      padding: 0.75rem;
      color: var(--colorSuccess);
    }

    &-delete {
      padding: 0.75rem;
      border-radius: 50%;
      cursor: pointer;

      @apply text-danger;

      &:hover {
        @apply text-danger-700;
      }
    }

    .progress {
      position: absolute;
      height: 100%;
      transition: width 0.5s;
    }

    @for $i from 1 through 100 {
      .progress-#{$i} {
        background: var(--progressColor);
        width: $i * 1%;
      }
    }
  }
}

.soft-drag-drop {
  &-container {
    width: calc(100% - (9px + 0.5rem));
    border: var(--fileInputBorder);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    position: relative;

    .selected-files-length {
      @apply text-primary;
      margin-bottom: 0.75rem;
      font-size: 0.825rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &-description {
    margin-top: 0.75rem;
    padding: 0 0.75rem;
    text-align: center;
  }
}

soft-common-file-input {
  width: 100%;
}
