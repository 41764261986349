@use '../abstracts/variables';

.combo-box-panel {
  @apply shadow-lg;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 1rem;
  background-color: var(--colorSurface);
  padding: 0;
  font-family: var(--fontFamily);
  z-index: 1001;
  overflow-y: auto;
  max-height: 90vh;

  &-items {
    max-height: 90vh;
  }
  &-items-multi {
    max-height: calc(90vh - 4rem);
  }

  .select-option-header {
    padding: 0.5rem 0.75rem 0.125rem 0.75rem;
    min-height: 0.5rem;
    font-size: 0.75rem;
    font-weight: bold;
  }

  .select-option-separator {
    padding: 0 0.75rem;
    color: var(--colorTextLighter);
  }

  .combo-box-select-option {
    padding: 1rem 0.75rem;
    min-height: 1rem;
    font-size: 0.875rem;

    &:hover {
      background-color: var(--colorHover) !important;
    }

    &.disabled {
      color: var(--colorTextLighter);
    }

    &.selected {
      background: var(--colorSelected);
    }
  }
}

@media screen and (min-width: variables.$breakpointS) {
  .combo-box-panel {
    position: absolute;
    width: calc(100% + 2px);
    top: calc(2rem - 1px);
    right: -1px;
    left: auto;
    bottom: auto;
    font-size: 1rem;
    box-sizing: border-box;
    border-width: 0 1px 1px 1px;
    border-color: var(--colorSecondary);
    border-style: solid;
    max-height: 250px;

    &-items {
      max-height: 250px;
    }
    &-items-multi {
      max-height: 250px;
    }

    .combo-box-select-option {
      padding: 0.5rem 0.75rem;
      min-height: 0.5rem;
    }
  }
}
