soft-signature-input {
  width: 100%;
}

.signature-input {
  width: 100%;
  border: var(--inputBorder);
  padding: 0.5rem;
  min-height: 8rem;
  background: var(--inputBackground);
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &.readonly {
    @apply bg-gray-200;
  }
}

.signature-image {
  min-width: 100%;
  min-height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 1rem;
}

.signature-input small {
  @apply text-xs text-gray-400;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  text-align: center;
}

.delete-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
