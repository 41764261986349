.soft-label {
  font-family: var(--fontFamily);
  color: var(--colorText);

  &-top,
  &.top {
    font-size: 0.75rem;
    font-weight: bold;
  }

  &-right,
  &.right {
    font-size: 0.875rem;
    padding-left: 0.75rem;
    font-weight: 300;
  }

  &-left,
  &.left {
    font-size: 0.875rem;
    padding-right: 0.75rem;
    font-weight: 300;
  }
}
