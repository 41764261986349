@use '../abstracts/variables';
.soft-backdrop {
  content: '';
  background-color: var(--modalOverlayBackground);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
  display: block;

  @media screen and (min-width: variables.$breakpointS) {
    &.mobile-only {
      display: none;
    }
  }
}
