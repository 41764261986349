input[type='submit'] {
  @apply text-primary-contrast bg-primary hover:bg-primary-700 text-sm #{!important};
  margin: 0;
  width: auto;
  overflow: visible;
  border-radius: 0;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: none;
  text-align: inherit;
  padding: 0.625rem 1rem 0.5rem 1rem;
  font-family: var(--fontFamily);
  font-weight: 700;
  transition: all 0.33s ease;
  cursor: pointer;

  &:disabled {
    @apply bg-primary-200 #{!important};
    color: var(--colorTextLight) #{!important};
    cursor: not-allowed;
  }
}

.soft-button {
  @apply text-sm hover:bg-hover;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0.625rem 1rem 0.5rem 1rem;
  font-family: var(--fontFamily);
  font-weight: 700;
  transition: all 0.33s ease;

  &:disabled {
    @apply bg-primary-100;
    color: var(--colorTextLight);
    cursor: not-allowed;
  }

  &-primary,
  &.primary {
    @apply text-primary-contrast bg-primary hover:bg-primary-700;

    &:disabled {
      @apply bg-primary-100;
      color: var(--colorTextLight);
    }
  }

  &-secondary,
  &.secondary {
    @apply text-secondary-contrast bg-secondary hover:bg-secondary-600;

    &:disabled {
      color: var(--colorTextLight);
      @apply bg-secondary-200;
    }
  }

  &-tertiary,
  &.tertiary {
    @apply text-tertiary-contrast bg-tertiary hover:bg-tertiary-700;

    &:disabled {
      color: var(--colorTextLight);
      @apply bg-tertiary-200;
    }
  }

  &-accent,
  &.accent {
    @apply text-accent-contrast bg-accent hover:bg-accent-700 hover:text-accent-contrast-700;

    &:disabled {
      color: var(--colorTextLight);
      @apply bg-accent-200;
    }
  }

  &-danger,
  &.danger {
    @apply bg-danger text-danger-contrast hover:bg-danger-700;

    &:disabled {
      @apply bg-danger-200;
    }
  }

  &-outline,
  &.outline {
    border: 1px solid var(--colorPrimary);
    background-color: transparent;
    @apply text-primary;

    &:hover:not(:disabled) {
      background-color: var(--colorPrimary100);
    }
    &:disabled {
      color: var(--colorTextLight);
      border: 1px solid var(--colorPrimary200);
    }
  }

  &-surface,
  &.surface {
    @apply bg-surface hover:bg-surface-secondary;

    &:disabled {
      color: var(--colorTextLight);
    }
  }

  &-surface-secondary,
  &.surface-secondary {
    @apply bg-surface-secondary hover:bg-surface-tertiary;

    &:disabled {
      color: var(--colorTextLight);
    }
  }

  &-link,
  &.link {
    color: var(--colorLink);
    background: transparent;
    cursor: pointer !important;
    border: 0;

    &:hover:not(:disabled) {
      text-decoration: underline;
      transition: all 0.3s ease;
    }

    @apply disabled:text-primary-300;

    &:disabled {
      cursor: not-allowed;
    }

    &-primary,
    &.primary {
      @apply text-primary disabled:text-primary-300;
    }

    &-secondary,
    &.secondary {
      @apply text-secondary disabled:text-secondary-300;
    }

    &-tertiary,
    &.tertiary {
      @apply text-tertiary disabled:text-tertiary-300;
    }

    &-accent,
    &.accent {
      @apply text-accent disabled:text-accent-300;
    }

    &-danger,
    &.danger {
      color: var(--colorDanger);
      background: transparent;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  &-small,
  &.small {
    padding: 0.325rem 1rem 0.275rem 1rem;
    @apply text-xs;
  }
}
